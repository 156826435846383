import * as React from 'react';
import { ParagraphSmall } from 'lib/components/styles/typography';
import {
  Content,
  ContentBody,
  ContentHeader,
  ContentHeaderWrap,
  ErrorMessage,
  Form,
  ModalItem,
  SpanSmallBold,
} from 'lib/components/modal/importModal/Components';
import { CloseButton } from 'lib/components';
import DragAndDrop from 'lib/components/inputs/DragAndDrop';

import { theme } from 'lib/style';
import { Button } from 'react-covideo-common';
import { MdFileDownload } from 'react-icons/md';
import styled from 'styled-components/macro';
import { exportCSVFile } from 'lib/utils/functions';
import { IoMdClose } from 'react-icons/io';
import { useAuth } from 'lib/context';
import { checkIfNewManageUsersChangesVisible } from 'lib/utils/productFeature';

const ButtonWrapper = styled.div`
  margin-top: 23px;
  margin-bottom: 23px;
  text-align: center;
`;

const TextWrapper = styled.div`
  display: flex;
  padding-top: 5px;
`;

const parseOptions = {
  header: false,
  dynamicTyping: true,
  skipEmptyLines: true,
};

const exportHeader = {
  firstName: 'First_Name',
  lastName: 'Last_Name',
  email: 'Email',
  type: 'Phone',
  phone: 'Mobile_Phone',
};

type Props = {
  title: string;
  text: string;
  disabled: boolean;
  uploadError: boolean;
  errorMessage: string;
  onUpload(csv: string[][], fileInfo: { name: string }): void;
  handleModalClose(): void;
  isEmail2Mandatory?: boolean;
  isAutomotive: boolean;
};

export const AddBulkUser = ({
  title,
  disabled,
  text,
  uploadError,
  errorMessage,
  handleModalClose,
  onUpload,
  isEmail2Mandatory = false,
  isAutomotive,
}: Props) => {
  const { userData } = useAuth();
  const hideAutomotiveRoleForDEV =
    checkIfNewManageUsersChangesVisible(userData);
  const downloadTemplate = async () => {
    let headers: { [key: string]: string } = exportHeader;

    if (!!isAutomotive && !hideAutomotiveRoleForDEV) {
      headers = {
        ...exportHeader,
        automotiveRole: 'Automotive_Role',
      };
    }

    if (isEmail2Mandatory) {
      headers = {
        ...headers,
        email2: 'Email_2',
      };
    }
    exportCSVFile(headers, [], 'import_users');
  };
  return (
    <ModalItem>
      <Content style={{ width: '530px' }}>
        <ContentHeaderWrap>
          <ContentHeader>{title}</ContentHeader>
          <CloseButton
            padding='24px 10px 0px'
            disabled={disabled}
            onClick={handleModalClose}
          />
        </ContentHeaderWrap>
        <ContentBody>
          <ParagraphSmall>{text}</ParagraphSmall>
          {!!isAutomotive && !hideAutomotiveRoleForDEV && (
            <TextWrapper>
              <ParagraphSmall>
                Accepted Automotive Roles:{' '}
                <SpanSmallBold>
                  {' '}
                  Salesperson, Sales Manager, Service Manager, Service Advisor,
                  Service Technician, BDC
                </SpanSmallBold>
              </ParagraphSmall>
            </TextWrapper>
          )}
          <ButtonWrapper>
            <Button
              variant='secondary'
              icon={<MdFileDownload />}
              text='Download Template'
              onClick={downloadTemplate}
            />
          </ButtonWrapper>

          <Form
            onSubmit={e => {
              e.stopPropagation();
            }}
          >
            <>
              <DragAndDrop
                showIcon={false}
                onFileLoaded={onUpload}
                parserOptions={parseOptions}
                button={true}
                width={528}
                height={232}
              />
              {uploadError && (
                <ErrorMessage>
                  <span>
                    <IoMdClose
                      size={32}
                      color={theme.palette.primaryRedDanger}
                    />
                    {errorMessage}
                  </span>
                </ErrorMessage>
              )}
            </>
          </Form>
        </ContentBody>
      </Content>
    </ModalItem>
  );
};

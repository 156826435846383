import React, { useState } from 'react';
import { MdDownload, MdSend } from 'react-icons/md';
import { Dropdown, Search } from 'lib/components';
import {
  deliveryMethodOptions,
  Filters,
  IDropdown,
  statusOptions,
} from '../const';
import { downloadAnalyticsReport } from 'lib/api';
import { useToastError } from 'lib/hooks';
import { Button } from 'react-covideo-common';
import { Gap } from 'lib/components/styles/layout';

interface IProps {
  filter: {
    filters: Filters | { [key: string]: string };
    setFilters: React.Dispatch<React.SetStateAction<{} | Filters>>;
  };
  setModal: React.Dispatch<
    React.SetStateAction<'report' | 'email_preview' | null>
  >;
  videoId: string;
}

export const CardStatsViewersQuickFilters = ({
  filter: { setFilters, filters },
  setModal,
  videoId,
}: IProps) => {
  const [statusOptionValue, setStatusOptionValue] = useState<IDropdown | null>(
    statusOptions[0]
  );
  const [deliveryOptionValue, setDeliveryOptionValue] =
    useState<IDropdown | null>(deliveryMethodOptions[0] as IDropdown);
  const [isDownloading, setDownloading] = useState<boolean>(false);
  const { showError } = useToastError();

  const isBlankValue = (val: any) =>
    val === 'all' || val === null || val === undefined || val === '';

  const setFilter = (e: string | null, key: keyof Filters) => {
    setFilters((prevState: Filters) => {
      const { [key]: _, ...rest } = prevState;
      return {
        ...(!isBlankValue(e) ? { ...prevState, [key]: e } : { ...rest }),
      };
    });
  };

  const downloadReport = async () => {
    setDownloading(true);
    try {
      await downloadAnalyticsReport({ videoId, params: filters });
    } catch (error) {
      showError(error);
    }
    setDownloading(false);
  };

  return (
    <Gap width='100%' justifyContent='space-between' m='0 0 16px 0'>
      <Gap gap='8px' style={{ flex: 1 }}>
        <Search width='220px' onSearch={e => setFilter(e, 'search')} />
        <Dropdown
          value={deliveryOptionValue}
          placeholder='All Delivery Methods'
          options={deliveryMethodOptions}
          creatable={false}
          onChange={(e: IDropdown) => {
            setDeliveryOptionValue(e);
            setFilter(e.value, 'SMS');
          }}
          extendStyles={{
            container: {
              width: '215px',
            },
            option: {
              textAlign: 'left',
            },
          }}
        />
        <Dropdown
          dropdownHeight={200}
          value={statusOptionValue}
          placeholder='All Statuses'
          creatable={false}
          options={statusOptions}
          onChange={(e: IDropdown) => {
            setStatusOptionValue(e);
            setFilter(e.value, 'emailStatus');
          }}
          extendStyles={{
            container: {
              width: '155px',
            },
            option: {
              textAlign: 'left',
            },
          }}
        />
      </Gap>
      <Gap gap='8px'>
        <Button
          text='Send'
          icon={<MdSend size={'24px'} />}
          onClick={() => setModal('report')}
          title='Send Report'
        />

        <Button
          onClick={downloadReport}
          text={isDownloading ? 'Downloading' : 'Download'}
          disabled={isDownloading}
          icon={<MdDownload size={'24px'} />}
          title='Download Report'
        />
      </Gap>
    </Gap>
  );
};

export enum ReportTypes {
  VIEWS = 'views',
  UNIQUE_VIEWS = 'uniqueViews',
  ENGAGEMENT = 'engagements',
  CTA = 'clicks',
  OPENED = 'opened-emails',
  DELIVERED = 'delivered-emails',
  DEFERRED = 'deferred-emails',
  SPAM = 'spam-emails',
  BOUNCED = 'bounced-emails',
  DROPPED = 'dropped-emails',
  UNSUBSCRIBED = 'unsubscribed-emails',
  RECORDED = 'videos',
  SENT = 'sent-emails',
  SENT_UNIQUE = 'sent-unique',
  VIDEO_ATTRIBUTES = 'video-attributes',
  AVERAGE_VIDEO_DURATION = 'average-video-duration',
  LAST_LOGIN = 'lastLogin',
  // CDS report types
  REPAIR_ORDER = 'repair-orders',
  TOTAL_RO_ITEMS = 'total-ro-items',
  REQUIRED_RO_ITEMS = 'required-ro-items',
  OPTIONAL_RO_ITEMS = 'optional-ro-items',
  TOTAL_AMOUNT = 'total-amount',
  AMOUNT_APPROVED = 'amount-approved',
  CLOSE_RATIO = 'close-ratio',
  TIME_WATCHED = 'time-watched',
}

export enum REPORT_GROUP_TYPE {
  POPULAR = 'popular',
  COVIDEO = 'covideo',
  SERVICE = 'service',
}

export const reportTypes: { [key: string]: string } = Object.assign(
  {},
  ReportTypes
);

export const reportFieldType = {
  COUNT: 'cnt',
  AVERAGE: 'average',
  MAX: 'max',
};

export const reportData = {
  [reportTypes.RECORDED]: {
    label: 'Recorded Videos',
    url: 'metrics/reports/videos',
    field: reportFieldType.COUNT,
    isPercentage: false,
    tableFields: [
      {
        value: 'title',
        label: 'Video Title',
      },
      {
        value: 'recordedAt',
        label: 'Date Recorded',
      },
    ],
    description: 'Number of personalized videos recorded by staff',
    group: REPORT_GROUP_TYPE.POPULAR,
  },
  [reportTypes.VIEWS]: {
    label: 'Total Views',
    url: 'metrics/reports/views',
    field: reportFieldType.COUNT,
    isPercentage: false,
    tableFields: [
      {
        value: 'title',
        label: 'Video Title',
      },
      {
        value: 'contactName',
        label: 'Name',
      },
      {
        value: 'email',
        label: 'Email Address',
      },
      {
        value: 'date',
        label: 'Date Viewed',
      },
      {
        value: 'watched',
        label: 'Watched %',
      },
    ],
    description: 'Cumulative number of views across all videos',
    group: REPORT_GROUP_TYPE.POPULAR,
  },
  [reportTypes.CTA]: {
    label: 'CTA Clicks',
    url: 'metrics/reports/clicks',
    field: reportFieldType.COUNT,
    isPercentage: false,
    tableFields: [
      {
        value: 'date',
        label: 'Click Date',
      },
      {
        value: 'email',
        label: 'Email Address',
      },
      {
        value: 'cta',
        label: 'CTA',
      },
      {
        value: 'linkSet',
        label: 'CTA Set',
      },
      {
        value: 'template',
        label: 'Landing Page',
      },
    ],
    description:
      'Count of clicks on call-to-action buttons on your landing page',
    group: REPORT_GROUP_TYPE.POPULAR,
  },
  [reportTypes.ENGAGEMENT]: {
    label: 'Engagement Rate',
    url: 'metrics/reports/engagements',
    field: reportFieldType.AVERAGE,
    isPercentage: true,
    tableFields: [
      {
        value: 'contactName',
        label: 'Name',
      },
      {
        value: 'email',
        label: 'Email Address',
      },
      {
        value: 'title',
        label: 'Video Title',
      },
      {
        value: 'date',
        label: 'Date Viewed',
      },
      {
        value: 'watched',
        label: 'Watched %',
      },
    ],
    description:
      'Percentage of recipients who engaged with the videos (e.g., opened, clicked)',
    group: REPORT_GROUP_TYPE.POPULAR,
  },
  [reportTypes.OPENED]: {
    label: 'Opened',
    url: 'metrics/reports/opened-emails',
    field: reportFieldType.COUNT,
    isPercentage: false,
    tableFields: [
      {
        value: 'contactFullName',
        label: 'Name',
      },
      {
        value: 'email',
        label: 'Email Address',
      },
      {
        value: 'date',
        label: 'Date Sent',
      },
      {
        value: 'title',
        label: 'Video Title',
      },
      {
        value: 'status',
        label: 'View Status',
      },
      {
        value: 'lastViewDate',
        label: 'Last Viewed',
      },
    ],
    description: 'Number of times recipients opened your videos',
    group: REPORT_GROUP_TYPE.COVIDEO,
  },
  [reportTypes.DELIVERED]: {
    label: 'Delivered',
    url: 'metrics/reports/delivered-emails',
    field: reportFieldType.COUNT,
    isPercentage: false,
    tableFields: [
      {
        value: 'contactFullName',
        label: 'Name',
      },
      {
        value: 'email',
        label: 'Email Address',
      },
      {
        value: 'date',
        label: 'Date Sent',
      },
      {
        value: 'title',
        label: 'Video Title',
      },
      {
        value: 'status',
        label: 'View Status',
      },
      {
        value: 'lastViewDate',
        label: 'Last Viewed',
      },
    ],
    description: "Videos successfully delivered to recipients' inboxes",
    group: REPORT_GROUP_TYPE.COVIDEO,
  },
  [reportTypes.DEFERRED]: {
    label: 'Deferred',
    url: 'metrics/reports/deferred-emails',
    field: reportFieldType.COUNT,
    isPercentage: false,
    tableFields: [
      {
        value: 'name',
        label: 'Name',
      },
      {
        value: 'email',
        label: 'Email Address',
      },
      {
        value: 'date',
        label: 'Date Deferred',
      },
    ],
    description:
      'The video sent, but the recipient’s mail server delayed delivery',
    group: REPORT_GROUP_TYPE.COVIDEO,
  },
  [reportTypes.SPAM]: {
    label: 'Spam',
    url: 'metrics/reports/spam-emails',
    field: reportFieldType.COUNT,
    isPercentage: false,
    tableFields: [
      {
        value: 'name',
        label: 'Name',
      },
      {
        value: 'email',
        label: 'Email Address',
      },
      {
        value: 'date',
        label: 'Date',
      },
    ],
    description: 'Videos marked as spam by recipient email providers',
    group: REPORT_GROUP_TYPE.COVIDEO,
  },
  [reportTypes.BOUNCED]: {
    label: 'Bounced',
    url: 'metrics/reports/bounced-emails',
    field: reportFieldType.COUNT,
    isPercentage: false,
    tableFields: [
      {
        value: 'name',
        label: 'Name',
      },
      {
        value: 'email',
        label: 'Email Address',
      },
      {
        value: 'date',
        label: 'Date Bounced',
      },
    ],
    description:
      'The videos that failed to reach recipients due to undeliverable addresses',
    group: REPORT_GROUP_TYPE.COVIDEO,
  },
  [reportTypes.DROPPED]: {
    label: 'Dropped',
    url: 'metrics/reports/dropped-emails',
    field: reportFieldType.COUNT,
    isPercentage: false,
    tableFields: [
      {
        value: 'name',
        label: 'Name',
      },
      {
        value: 'email',
        label: 'Email Address',
      },
      {
        value: 'date',
        label: 'Date Dropped',
      },
    ],
    description:
      'Videos that failed to reach recipients due to technical issues or restrictions',
    group: REPORT_GROUP_TYPE.COVIDEO,
  },
  [reportTypes.UNSUBSCRIBED]: {
    label: 'Unsubscribed',
    url: 'metrics/reports/unsubscribed-emails',
    field: reportFieldType.COUNT,
    isPercentage: false,
    tableFields: [
      {
        value: 'name',
        label: 'Name',
      },
      {
        value: 'email',
        label: 'Email Address',
      },
      {
        value: 'date',
        label: 'Date Unsubscribed',
      },
    ],
    description:
      'Count of recipients who opted out of receiving further videos',
    group: REPORT_GROUP_TYPE.COVIDEO,
  },
  [reportTypes.SENT]: {
    label: 'Sent',
    url: 'metrics/reports/sent-emails',
    field: reportFieldType.COUNT,
    isPercentage: false,
    tableFields: [
      {
        value: 'contactFullName',
        label: 'Name',
      },
      {
        value: 'email',
        label: 'Email Address',
      },
      {
        value: 'date',
        label: 'Date Sent',
      },
      {
        value: 'title',
        label: 'Video Title',
      },
      {
        value: 'status',
        label: 'View Status',
      },
      {
        value: 'lastViewDate',
        label: 'Last Viewed',
      },
    ],
    description: 'Total amount of videos sent to recipients',
    group: REPORT_GROUP_TYPE.COVIDEO,
  },
  [reportTypes.VIDEO_ATTRIBUTES]: {
    label: 'Video Attributes',
    url: 'metrics/reports/video-attributes',
    field: reportFieldType.COUNT,
    isPercentage: false,
    tableFields: [
      {
        value: 'title',
        label: 'Attribute Name',
      },
      {
        value: 'attributeType',
        label: 'Type',
      },
      {
        value: 'valueCount',
        label: 'Values',
      },
      {
        value: 'videoCount',
        label: 'Total Videos',
      },
    ],
    description:
      'Metadata associated with each video, such as title, description, and tags   ',
    group: REPORT_GROUP_TYPE.COVIDEO,
  },
  [reportTypes.AVERAGE_VIDEO_DURATION]: {
    label: 'Avg. Video duration (sec)',
    url: 'metrics/reports/average-video-duration',
    field: reportFieldType.AVERAGE,
    isPercentage: false,
    tableFields: [
      {
        value: 'userFullName',
        label: 'Name',
      },
      {
        value: 'email',
        label: 'Email Address',
      },
      {
        value: 'duration',
        label: 'Duration',
      },
    ],
    description: 'Average length of videos in seconds',
    group: REPORT_GROUP_TYPE.COVIDEO,
  },
  [reportTypes.LAST_LOGIN]: {
    label: 'Last Login',
    hideWidget: true,
    tableFields: [],
    description: 'Timestamp of when a user most recently logged into Covideo',
    group: REPORT_GROUP_TYPE.COVIDEO,
  },
  [reportTypes.UNIQUE_VIEWS]: {
    label: 'Unique Views',
    description: 'Cumulative number of unique views across all videos',
    group: REPORT_GROUP_TYPE.COVIDEO,
    url: 'metrics/reports/uniqueViews',
    field: reportFieldType.COUNT,
    isPercentage: false,
    tableFields: [
      {
        value: 'title',
        label: 'Video Title',
      },
      {
        value: 'recordedAt',
        label: 'Date Recorded',
      },
    ],
  },
  [reportTypes.REPAIR_ORDER]: {
    label: 'No. of ROs',
    url: 'metrics/reports/repair-orders',
    field: reportFieldType.COUNT,
    isPercentage: false,
    tableFields: [
      {
        value: 'advisorName',
        label: 'Advisor name',
      },
      {
        value: 'date',
        label: 'Date',
      },
    ],
    isAutomotive: true,
    description: 'Total amount of repair orders',
    group: REPORT_GROUP_TYPE.SERVICE,
  },
  [reportTypes.TOTAL_RO_ITEMS]: {
    label: 'Total RO items',
    url: 'metrics/reports/total-ro-items',
    field: reportFieldType.COUNT,
    isPercentage: false,
    tableFields: [
      {
        value: 'advisorName',
        label: 'Advisor name',
      },
      {
        value: 'title',
        label: 'RO Title',
      },
      {
        value: 'date',
        label: 'Date',
      },
    ],
    isAutomotive: true,
    description: 'Overall count of items on repair orders',
    group: REPORT_GROUP_TYPE.SERVICE,
  },
  [reportTypes.REQUIRED_RO_ITEMS]: {
    label: 'Req. RO items',
    url: 'metrics/reports/required-ro-items',
    field: reportFieldType.COUNT,
    isPercentage: false,
    tableFields: [
      {
        value: 'advisorName',
        label: 'Advisor name',
      },
      {
        value: 'title',
        label: 'RO Title',
      },
      {
        value: 'date',
        label: 'Date',
      },
    ],
    isAutomotive: true,
    description: 'Number of items requested on repair orders',
    group: REPORT_GROUP_TYPE.SERVICE,
  },
  [reportTypes.OPTIONAL_RO_ITEMS]: {
    label: 'Opt RO items',
    url: 'metrics/reports/optional-ro-items',
    field: reportFieldType.COUNT,
    isPercentage: false,
    tableFields: [
      {
        value: 'advisorName',
        label: 'Advisor name',
      },
      {
        value: 'title',
        label: 'RO Title',
      },
      {
        value: 'date',
        label: 'Date',
      },
    ],
    isAutomotive: true,
    description: 'Number of items on repair orders approved by customers',
    group: REPORT_GROUP_TYPE.SERVICE,
  },
  [reportTypes.TOTAL_AMOUNT]: {
    label: 'Total amount',
    url: 'metrics/reports/total-amount',
    field: reportFieldType.COUNT,
    isPercentage: false,
    tableFields: [
      {
        value: 'advisorName',
        label: 'Advisor name',
      },
      {
        value: 'title',
        label: 'RO Title',
      },
      {
        value: 'date',
        label: 'Date',
      },
      {
        value: 'price',
        label: 'Price',
      },
    ],
    isAutomotive: true,
    description: 'Cumulative monetary value of all repair orders',
    group: REPORT_GROUP_TYPE.SERVICE,
  },
  [reportTypes.AMOUNT_APPROVED]: {
    label: 'Amount approved',
    url: 'metrics/reports/amount-approved',
    field: reportFieldType.COUNT,
    isPercentage: false,
    tableFields: [
      {
        value: 'advisorName',
        label: 'Advisor name',
      },
      {
        value: 'title',
        label: 'RO Title',
      },
      {
        value: 'date',
        label: 'Date',
      },
      {
        value: 'price',
        label: 'Price',
      },
    ],
    isAutomotive: true,
    description: 'Total monetary value of repair orders approved by customers',
    group: REPORT_GROUP_TYPE.SERVICE,
  },
  [reportTypes.CLOSE_RATIO]: {
    label: 'Close ratio',
    url: 'metrics/reports/close-ratio',
    field: reportFieldType.MAX,
    isPercentage: false,
    tableFields: [
      {
        value: 'advisorName',
        label: 'Advisor name',
      },
      {
        value: 'date',
        label: 'Date',
      },
      {
        value: 'ratio',
        label: 'Close ratio',
      },
    ],
    allowDecimals: true,
    isAutomotive: true,
    description:
      'Ratio of repair orders closed successfully out of total sent repair orders',
    group: REPORT_GROUP_TYPE.SERVICE,
  },
  [reportTypes.TIME_WATCHED]: {
    label: 'Time Watched',
    url: 'metrics/reports/time-watched',
    field: reportFieldType.COUNT,
    isPercentage: false,
    isTime: true,
    tableFields: [
      {
        value: 'contactName',
        label: 'Name',
      },
      {
        value: 'email',
        label: 'Email Address',
      },
      {
        value: 'title',
        label: 'Video Title',
      },
      {
        value: 'date',
        label: 'Date Viewed',
      },
      {
        value: 'time-watched',
        label: 'Time watched',
      },
    ],
    description: 'Number of seconds watched',
    group: REPORT_GROUP_TYPE.COVIDEO,
  },
};
// not part of reportData since it is added to custom reports only (for now)
export const SENT_UNIQUE_REPORT_DATA = {
  label: 'Sent Unique',
  hideWidget: true,
  tableFields: [],
  description: 'Total amount of videos sent to unique recipients',
  group: REPORT_GROUP_TYPE.POPULAR,
};

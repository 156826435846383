import React from 'react';
import { useField } from 'formik';
import styled, { CSSProperties } from 'styled-components/macro';
import { FormikErrorMessage } from './FormikErrorMessage';
import { FormikLabel } from './FormikLabel';
import { theme } from 'lib/style';

const TextInput = styled.input`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #272a32;
  border-radius: 4px;
  height: 40px;
  width: 100%;
  box-sizing: border-box;
  border: solid 1px #e1e2e5;
  padding: 8px 12px;
  &:focus {
    outline: none;
    border: solid 1px ${theme.palette.covideoBlue100};
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

interface CustomInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  isRequired: boolean;
  extendStyles?: {
    errorWrapper?: CSSProperties;
  };
}

export const FormikInputField = ({
  label,
  isRequired,
  extendStyles,
  ...props
}: CustomInputProps) => {
  const [field, meta] = useField(props);
  return (
    <div style={{ width: '100%' }}>
      {label && (
        <FormikLabel label={label} name={props.name} isRequired={isRequired} />
      )}
      <TextInput {...field} {...props} id={props.name} />
      <FormikErrorMessage meta={meta} extendStyles={extendStyles} />
    </div>
  );
};

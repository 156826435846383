import React, { useEffect, useState, useCallback } from 'react';
import styled, { css, useTheme } from 'styled-components/macro';
import { theme } from 'lib/style';
import {
  VehiclePropertiesGrid,
  VehicleSearch,
  SendData,
  CREATE_NEW_ID,
  MAX_EMAIL_RECIPIENTS,
  MAX_PHONE_RECIPIENTS,
  noSmsMsg,
  RecipientTypeEnum,
  Recipient,
  MultipleRecipientType,
  RecipientSearch,
  AddCustomer,
  LandingPageCard,
  SendShareVideoForm,
  ModalHeader,
  HeaderSide,
  LinkWrapper,
  LinkBtn,
  HeaderCenter,
  Actions,
  ModalNoQuotePrompt,
  LandingPageType,
  defaultLPOption,
  replaceVariables,
  savePreviewCookie,
  getPreviewUrl,
  TabWrapper,
  ModalBody,
  getTabs,
  EmailContent,
  BUILDER_IDS,
  Label,
  LIMIT_CONTACTS_PAGE_SIZE,
  LIMIT_GROUPS_PAGE_SIZE,
  Option,
  prepareTemplateLandingPageOptions,
  prepareWebsiteOverlayOptions,
  prepareLinksetCTAOptions,
  CONTACTS_SORT_KEY,
  GROUPS_SORT_KEY,
} from '../';
import { addEmailReceiver, ContactListItem, GroupListItem } from 'lib/api';
import {
  QuoteCard,
  SmsEditor,
  CardWithSwitch,
  CardAddQuote,
  ModalDeleteQuotePrompt,
  AddMissingInfo,
  AddMissingName,
} from 'app/pages/repairOrders/components';
import {
  useAuth,
  VideoRequest,
  InventoryItem,
  SaleOfferItem,
  AttachedFile,
  FileUploadStatus,
} from 'lib/context';
import { RepairOrderStatus, VideoEmailStatus } from 'lib/const';
import { isEmpty, Dictionary, toNumber, uniqBy, debounce, keyBy } from 'lodash';
import {
  CheckboxInput,
  ButtonPillSwitch,
  LoadingIndicator,
  Dropdown,
  ModalDelete,
} from 'lib/components';
import { EMAIL_REGEX, PHONE_REGEX } from 'lib/utils/regexes';
import { checkIfScheduleAccessible } from 'lib/utils/automotiveRolePermissionChecks';
import { successToast } from 'lib/components/toasts/success';
import {
  checkIfFreemiumAndInvalidateForCheckList,
  checkIfOverlayValid,
  prepareTextForSMS,
} from 'lib/utils/functions';
import { RecipientsBadge } from './RecipientsBadge';
import {
  MdClose,
  MdLock,
  MdOutlineEmail,
  MdSave,
  MdScheduleSend,
} from 'react-icons/md';
import { BiLinkExternal } from 'react-icons/bi';
import { IoMdLock } from 'react-icons/io';
import { RecipientType, useToastError } from 'lib/hooks';
import { useGetQuickshareDetails } from 'lib/api/quickshare/useGetQuickshareDetails';
import { VideoTitleAndThumbnail } from '../components';
import { EmailBuilder } from './EmailBuilder';
import { EmailBuilderEditor } from './EmailBuilderEditor';
import { errorToast } from 'lib/components/toasts/error';
import Attachments from '../components/Attachments';
import dayjs from 'dayjs';
import { DATETIME_MERIDIEM, getRoundedDate } from 'lib/utils/datetime';
import {
  dropdownItems,
  ExpDropdownItemsProps,
  LINK_EXPIRATION_TYPES,
} from 'app/pages/library/videos/components/videoExpiration/videoExpiration';
import { useQueryClient } from 'react-query';
import { ModalVideoExpiration } from 'lib/components/modal/ModalVideoExpiration';
import { ModalScheduleSendAndShare } from 'lib/components/modal/ModalScheduleSendAndShare';
import DeletionInfo from 'app/pages/video/videoDetails/components/DeletionInfo';
import { useDeleteScheduleMutation } from 'lib/api/schedule/deleteSingleSchedule';
import { useUpdateSingleScheduleMutation } from 'lib/api/schedule/updateSingleSchedule';
import { Schedule } from 'lib/api/schedule/types';
import { EditScheduleDeliveryTimeCard } from './EditScheduleDeliveryTimeCard';
import { QuoteItem, RepairOrderItem } from 'lib/api/repairOrders/types';
import { useCreateRepairOrder } from 'lib/api/repairOrders/createRepairOrder';
import {
  PackageNameLabel,
  checkIfFeatureIsEnabled,
  productFeature,
  checkIfConversationsAvailable,
} from 'lib/utils/productFeature';
import ProductInfoTooltip from 'lib/components/ProductInfoTooltip/ProductInfoTooltip';
import { ScheduleStatus } from 'app/pages/account/userAccount/schedule/const';
import { useSendAndShare } from 'lib/context/send-and-share/provider';
import { useSendVideoEmail } from 'lib/api/email/useSendVideoEmail';
import { SendEmailParams } from 'lib/api/email/types';
import { NotSurePopup } from 'lib/components/magic/popups/NotSurePopup';
import { STANDARD_DATE_FORMAT } from 'lib/const/DateFormat';
import { useStartConversationMutation } from 'lib/api/conversations/startConversation';
import { IStartConversationBody } from 'app/pages/conversations/modals/ModalStartConversation';
import { useSendSMSMutation } from 'lib/api/conversations/sendSMSMessage';
import selectors from '../../../../../../../../cypress/selectors';
import { Button } from 'react-covideo-common';
import { AiFillInfoCircle } from 'react-icons/ai';
import {
  MAX_TRIAL_EMAILS,
  canSendMoreTrialEmails,
} from 'lib/const/SendAndShare';
import { checkIfTrialUser } from 'lib/utils/userRoles';
import { WHITELABEL_NAME } from 'lib/api/whitelabel/getWhiteLabel';
import { ILead, useLeadsQuery } from 'lib/api/leads/getLeads';
import { useAddLeadMutation } from 'lib/api/leads/useAddLead';
import { useEditVideoRequestMutation } from 'lib/api/videoRequests/useEditVideoRequestMutation';
import {
  getContacts,
  useContactsQuery,
} from 'lib/api/contacts/useContactsQuery';
import { useCreateContactMutation } from 'lib/api/contacts/useCreateContactMutation';
import { IContact } from 'lib/api/contacts/getSingleContact';
import { useQueryGroups } from 'lib/api/group/useQueryGroups';
import { useUpdateContactMutation } from 'lib/api/contacts/useUpdateContactMutation';
import {
  TimezonesOption,
  timezonesOptions,
} from 'lib/components/timezoneSelector/TimezoneSelector';
import { SendMultipleSMSRequest } from 'lib/api/sms/types';
import { useSendVideoSMS } from 'lib/api/sms/useSendVideoSMS';
import { parseDeliveryTimeToScheduledTimezone } from 'app/pages/account/userAccount/schedule/utils';
import { QUOTIBLE_LANDING_PAGE_TYPE_ID } from 'lib/const/LandingPage';
import { LimitExceedInfoBox } from './LimitExceedInfoBox';
import { useLeadGroupsQuery } from 'lib/api/leadGroups/useLeadGroupsQuery';

export const ContentWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  width: 1246px;
  max-width: 100%;
  margin: 0 auto;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 50px;
`;

const Main = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 830px;
  max-width: 100%;
`;
type SideProps = {
  animate?: boolean;
};
const Side = styled.div<SideProps>`
  box-sizing: border-box;
  width: 384px;
  max-width: 100%;
  padding: 16px;
  background: ${theme.palette.blue02};
  border: 1px solid ${theme.palette.gray20};
  border-radius: 7px;
  position: sticky;
  top: 0;
  ${({ animate }) =>
    animate &&
    css`
      @keyframes fadeInUp {
        0% {
          opacity: 0;
          transform: translateY(8px);
        }
        100% {
          opacity: 1;
          transform: translateY(0);
        }
      }
      opacity: 0;
      animation: fadeInUp 150ms ease-in-out forwards;
    `}
`;
const CheckboxInputWrapper = styled.div`
  margin-bottom: 24px;
  display: flex;
  align-items: center;
`;

const CheckboxLabel = styled.label`
  margin-left: 12px;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.palette.gray100};
`;

const EditScheduleModalTitle = styled.span`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  font-feature-settings:
    'tnum' on,
    'lnum' on,
    'ss02' on;
  color: ${theme.palette.covideoBlue100};
`;

type InfoProps = {
  isDanger?: boolean;
};
const InformationBox = styled.div<InfoProps>`
  background-color: ${props =>
    props.isDanger ? theme.palette.red02NonAlpha : theme.palette.blue02};
  border: 1px solid
    ${props =>
      props.isDanger ? theme.palette.red05 : theme.palette.covideoBlack05};
  color: ${props =>
    props.isDanger ? theme.palette.red100 : theme.palette.covideoBlue100};
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 12px;
  font-size: 14px;
`;
const TrialTextWrapper = styled.div`
  margin-left: 10px;
`;

type Props = {
  activeTab: string;
  setActiveTab: (tab: string) => void;
  handleModalClose: () => void;
  editSchedule?: Schedule | null;
  setShowFreemiumAlert: Function;
  notify: boolean;
  setNotify: Function;
};

export const SendTab = ({
  activeTab,
  setActiveTab,
  handleModalClose,
  editSchedule,
  setShowFreemiumAlert,
  notify,
  setNotify,
}: Props) => {
  const { overlays, templates, linksets, video, vin, changeVin, emailLimit } =
    useSendAndShare();
  const { mutateAsync: sendVideoEmail } = useSendVideoEmail(video.id);
  const { mutateAsync: sendVideoSMS } = useSendVideoSMS(video.id);
  const { mutateAsync: editVideoRequestMutation } =
    useEditVideoRequestMutation();
  const themes = useTheme();
  const { showError } = useToastError();
  const { userData, whitelabel } = useAuth();
  const { customer } = userData;
  const emailsSentLimit = customer.emailsSentLimit || MAX_EMAIL_RECIPIENTS;

  const tabs = getTabs(userData);

  const isShared =
    !!video.folderAccess && video.folderAccess === 'edit' ? 1 : 0;
  const isExpirationActivated = userData.customer.videoExpiration === '1';

  const { mutateAsync: deleteSchedule, isLoading: isLoadingDeleteSchedule } =
    useDeleteScheduleMutation();

  const { mutateAsync: updateSchedule, isLoading: isLoadingUpdateSchedule } =
    useUpdateSingleScheduleMutation();

  const {
    data: quickshareDetails,
    isFetching: shareUrlLoading,
    changeQuickshareParams,
  } = useGetQuickshareDetails({ videoId: video.id });
  const videoUrl = quickshareDetails?.data?.url || '';
  const reactionsEnabled =
    customer.reactionsPreference?.toString() === '1' &&
    !!video.reactionsPreference;
  const videoReplyEnabled =
    customer.allowReplies?.toString() === '1' && !!video.videoReplyEnabled;

  const [showLoading, setShowLoading] = useState(false);
  const [validForSend, setValidForSend] = useState(true);
  const [sendData, setSendData] = useState<SendData>();
  const [newContactData, setNewContactData] = useState<Recipient>();
  const [updateCustomer, setUpdateCustomer] = useState(false);
  const [tempVideoRequest, setTempVideoRequest] = useState(video.videoRequest);
  const [showModalNoQuotePrompt, setShowModalNoQuotePrompt] = useState(false);
  const [showModalScheduleSendAndShare, setShowModalScheduleSendAndShare] =
    useState(false);

  const isCustomLinkExp = (id: string) => {
    return id === LINK_EXPIRATION_TYPES.CUSTOM;
  };

  const dateStringServerTimezone = `${video.recordDate}-4:00`;
  const date = dayjs(new Date(dateStringServerTimezone));
  const dateOfCreation = date.format(STANDARD_DATE_FORMAT);

  const getCustomDateInitialValue = () => {
    const initStartDate = new Date();
    if (
      editSchedule &&
      editSchedule.expirationDropdownValue &&
      isCustomLinkExp(editSchedule.expirationDropdownValue)
    ) {
      return new Date(editSchedule.expirationCustomDateValue);
    }
    return initStartDate;
  };
  const [customDate, setCustomDate] = useState<Date>(
    getCustomDateInitialValue()
  );
  const [customOpened, setCustomOpened] = useState<boolean>(false);

  const getExpirationInitialValue = () => {
    if (editSchedule && editSchedule.expirationDropdownValue) {
      if (isCustomLinkExp(editSchedule.expirationDropdownValue)) {
        const date = new Date(editSchedule.expirationCustomDateValue);
        return {
          id: LINK_EXPIRATION_TYPES.CUSTOM,
          value: date,
          label: dayjs(date.toString()).format(DATETIME_MERIDIEM),
        };
      }
      const selectedDropdownItemList = dropdownItems.filter(
        item => item.id === editSchedule.expirationDropdownValue
      );
      if (selectedDropdownItemList.length === 1) {
        return {
          ...selectedDropdownItemList[0],
        };
      }
    }
    return {
      ...dropdownItems[0],
    };
  };
  const [expiration, setExpiration] = useState<ExpDropdownItemsProps>(
    getExpirationInitialValue()
  );

  const closeCustomLinkExpirationModal = () => {
    setCustomOpened(false);
  };

  const setLinkExpiration = () => {
    setExpiration({
      id: LINK_EXPIRATION_TYPES.CUSTOM,
      value: customDate,
      label: dayjs(customDate.toString()).format(DATETIME_MERIDIEM),
    });
    setCustomOpened(false);
  };

  const [templateOptions, setTemplateOptions] = React.useState<any>([]);
  const [selectedCta, setSelectedCta] = React.useState<any>({
    value: '',
    label: '',
  });
  const [ctaOptions, setCtaOptions] = React.useState<any>([]);
  const [selectedOverlay, setSelectedOverlay] = React.useState<any>({
    value: '',
    label: '',
  });
  const [overlayOptions, setOverlayOptions] = React.useState<Option[]>([]);
  const [sendVehicle, setSendVehicle] = useState(false);
  const [sendQuote, setSendQuote] = useState(false);
  const [vehicleContent, setVehicleContent] = useState<InventoryItem>();

  const [allowReactions, setAllowReactions] = useState(
    editSchedule ? !!editSchedule?.allowReactions : reactionsEnabled
  );
  const [allowVideoReply, setAllowVideoReply] = useState(
    editSchedule ? !!editSchedule?.allowVideoReply : videoReplyEnabled
  );
  const [tooManySMSRecipients, setTooManySMSRecipients] = useState(false);
  const [tooManyEmailRecipients, setTooManyEmailRecipients] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(defaultLPOption);
  const [sendEmailFlag, setSendEmailFlag] = useState(
    editSchedule ? !!editSchedule?.sendEmail : false
  );
  const [emailContent, setEmailContent] = useState<EmailContent>(
    editSchedule?.emailContent || {
      subject: '',
      body: '',
    }
  );
  const [sendSmsFlag, setSendSmsFlag] = useState(
    editSchedule ? !!editSchedule?.sendSms : false
  );
  const [smsContent, setSmsContent] = useState({
    body: editSchedule?.smsText || '',
  });
  const [showQuoteCard, setShowQuoteCard] = useState(false);
  const [recipientHasFirstName, setRecipientHasFirstName] = useState(false);
  const [newFirstName, setNewFirstName] = useState('');
  const [newFirstNameValid, setNewFirstNameValid] = useState(false);
  const [recipientHasLastName, setRecipientHasLastName] = useState(false);
  const [newLastName, setNewLastName] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [attachments, setAttachments] = useState<AttachedFile[]>(
    editSchedule?.attachments || []
  );
  const [attachmentUploadStatus, setAttachmentUploadStatus] = useState<
    FileUploadStatus[]
  >([]); // for users without files addon
  const [newEmailValid, setNewEmailValid] = useState(false);
  const [recipientHasEmail, setRecipientHasEmail] = useState(false);
  const [newPhone, setNewPhone] = useState('');
  const [newPhoneValid, setNewPhoneValid] = useState(false);
  const [recipientHasPhone, setRecipientHasPhone] = useState(false);
  const [updateCustomerEmail, setUpdateCustomerEmail] = useState(false);
  const [updateCustomerPhone, setUpdateCustomerPhone] = useState(false);
  const [updateCustomerName, setUpdateCustomerName] = useState(false);
  const [showModalDeleteQuotePrompt, setShowModalDeleteQuotePrompt] =
    useState(false);
  const [requiredQuoteItems, setRequiredQuoteItems] = useState<QuoteItem[]>([]);
  const [optionalQuoteItems, setOptionalQuoteItems] = useState<QuoteItem[]>([]);

  const [newCustomerData, setNewCustomerData] = useState<ContactListItem>();
  const [newCustomerCreatedId, setNewCustomerCreatedId] =
    useState(CREATE_NEW_ID);
  const [selectedRecipientsWithEmail, setSelectedRecipientsWithEmail] =
    useState<Dictionary<Recipient>>({});
  const [selectedRecipientsWithPhone, setSelectedRecipientsWithPhone] =
    useState<Dictionary<Recipient>>({});
  const [elementBeingEditedId, setElementBeingEditedId] = useState(
    BUILDER_IDS.EMPTY
  );

  // schedule EmailSMS states and setters
  const [scheduleDateTime, setScheduleDateTime] = useState<Date>(
    getRoundedDate(15)
  );
  const [scheduleTimezone, setScheduleTimezone] = useState<TimezonesOption>(
    timezonesOptions[0]
  );
  const [isGroupSelected, setIsGroupSelected] = useState(false);
  const [showConfirmDeleteScheduleModal, setShowConfirmDeleteScheduleModal] =
    useState<boolean>(false);
  const [firstLoadPassed, setFirstLoadPassed] = useState<boolean>(false);
  const { mutateAsync: addRepairOrder } = useCreateRepairOrder();

  const isQuotibleTemplateSelected =
    selectedTemplate.typeId === QUOTIBLE_LANDING_PAGE_TYPE_ID;

  useEffect(() => {
    // do not get user preferred timezone when editing scheduled Email/SMS
    if (editSchedule) {
      const parsedDate = parseDeliveryTimeToScheduledTimezone(
        editSchedule.deliveryTime
      );
      setScheduleDateTime(parsedDate as unknown as Date);
    }
    const userPreferredTimezone = timezonesOptions.filter(tz => {
      for (const value of tz.utc) {
        if (value === userData.timezone) {
          return tz;
        }
      }
      return null;
    });
    if (userPreferredTimezone.length > 0) {
      setScheduleTimezone(userPreferredTimezone[0]);
    }
  }, []);

  // flag to send sms only to contacts which have no email
  const [smsOnlyWithoutEmail, setSmsOnlyWithoutEmail] = useState(
    editSchedule ? !!editSchedule?.skipSmsFlag : false
  );

  const parseEditScheduleRecipientIds = (): (string | number)[] => {
    if (editSchedule?.leadIds && editSchedule?.leadIds.length > 0) {
      return editSchedule?.leadIds;
    }
    let contactIds: number[] = [];
    let groupIds: string[] = [];
    if (editSchedule?.contactIds && editSchedule?.contactIds.length > 0) {
      contactIds = editSchedule?.contactIds;
    }
    if (editSchedule?.groupIds && editSchedule?.groupIds.length > 0) {
      groupIds = editSchedule?.groupIds.map(
        (groupIds: number) => 'group-' + groupIds.toString()
      );
    }

    return [...contactIds, ...groupIds];
  };

  const [selectedRecipientIds, setSelectedRecipientIds] = useState<
    (string | number)[]
  >(editSchedule ? parseEditScheduleRecipientIds() : []);

  const constructRecipientsFromLeadsContactsGroups = (
    leadsActiveFlag: boolean,
    leads: ILead[],
    contacts: ContactListItem[],
    groupsActiveFlag: boolean,
    groups: GroupListItem[]
  ) => {
    const createGroupRecipients = (groupType: MultipleRecipientType) => {
      return groups.map(g => ({
        id: 'group-' + g.groupId,
        name: g.name,
        type: MultipleRecipientType.GROUP,
        multipleRecipients: (g?.contacts || []).map(groupContact => ({
          id: groupContact.contactId,
          email: groupContact.email,
          phone: groupContact.phone,
          firstName: groupContact.firstName,
          lastName: groupContact.lastName,
          name: `${groupContact.firstName} ${groupContact.lastName}`,
          type: groupType,
        })),
      }));
    };

    let tempRecipients: Recipient[] = [];
    if (leadsActiveFlag) {
      tempRecipients = leads.map(l => ({
        id: l.leadId,
        email: l.email,
        phone: l.phone,
        firstName: l.firstName,
        lastName: l.lastName,
        name: `${l.firstName} ${l.lastName}`,
        type: MultipleRecipientType.LEAD,
      }));

      if (groupsActiveFlag) {
        tempRecipients = [
          ...createGroupRecipients(MultipleRecipientType.LEAD),
          ...tempRecipients,
        ];
      }

      return tempRecipients;
    }
    tempRecipients = contacts.map(c => ({
      id: c.contactId,
      email: c.email,
      phone: c.phone,
      firstName: c.firstName,
      lastName: c.lastName,
      name: `${c.firstName} ${c.lastName}`,
      type: RecipientTypeEnum.CONTACT,
    }));
    if (groupsActiveFlag) {
      tempRecipients = [
        ...createGroupRecipients(MultipleRecipientType.CONTACT),
        ...tempRecipients,
      ];
    }
    return tempRecipients;
  };

  const parseEditScheduleRecipients = (): Recipient[] => {
    const leadsActiveFlag = !!(
      editSchedule?.leads && editSchedule.leads.length > 0
    );
    const leadsList = editSchedule?.leads || [];
    const contactsList = editSchedule?.contacts || [];
    const groupsList = editSchedule?.groups || [];
    const groupsActiveFlag = !!(
      editSchedule?.groups && editSchedule.groups.length > 0
    );
    return constructRecipientsFromLeadsContactsGroups(
      leadsActiveFlag,
      leadsList,
      contactsList,
      groupsActiveFlag,
      groupsList
    );
  };

  const countTotalEmailRecipients = (
    selectedRecipientIds: (string | number)[],
    recipientOptions: Recipient[]
  ): number => {
    let totalRecipients = 0;

    selectedRecipientIds.forEach(id => {
      const recipient = recipientOptions.find(r => r.id === id);

      if (recipient) {
        if (recipient.multipleRecipients) {
          // Filter out recipients without an email and count only those with email
          const recipientsWithEmail = recipient.multipleRecipients.filter(
            r => r?.email
          );
          totalRecipients += recipientsWithEmail.length;
        } else {
          totalRecipients += 1;
        }
      }
    });

    return totalRecipients;
  };

  const [selectedRecipient, setSelectedRecipient] = useState<
    Recipient[] | undefined
  >(editSchedule ? parseEditScheduleRecipients() : undefined);
  const [recipientOptions, setRecipientOptions] = useState<Recipient[]>(
    editSchedule ? parseEditScheduleRecipients() : []
  );
  const [recipientSearch, setRecipientSearch] = useState('');
  const [recipientPage, setRecipientPage] = useState(0);

  const [groupSearch, setGroupSearch] = useState('');
  const [groupPage, setGroupPage] = useState(0);

  const queryClient = useQueryClient();
  const isAutomotive = userData.isAutomotive;
  const isAutomotiveService = userData.isAutomotiveServiceRole;
  const isAutomotiveSales = userData.isAutomotiveSalesRole;
  const isSmsEnabledForCustomer =
    (customer.smsEnabled && customer.smsEnabled.toString() === '1') ||
    (customer.smsProfileId && customer.smsProfileId != '');
  const isSmsFeatureEnabled = checkIfFeatureIsEnabled(
    userData,
    productFeature.CONVERSATIONS
  );
  const isSchedulesEnabled = checkIfFeatureIsEnabled(
    userData,
    productFeature.SCHEDULES
  );
  const conversationAvailable = checkIfConversationsAvailable(userData);
  const { mutateAsync: startConversation } = useStartConversationMutation();
  const { mutateAsync: sendConversationSmS } = useSendSMSMutation();

  const isTrialUser = checkIfTrialUser(userData);

  // check if leads integration active, if active use leads instead of contacts
  const leadsIntegrationActive = !!customer?.leadsIntegrationActive;
  const isQoteCardEnabled = isAutomotiveService;
  const isVehicleCardEnabled = isAutomotiveSales;
  const enableMultipleRecipients = true;
  const enableGroupSend = enableMultipleRecipients && !isTrialUser;

  const onCreateContactSuccess = (contact?: IContact) => {
    setRecipientPage(0);
    if (contact && (contact.email || contact.phone) && contact.contactId) {
      setRecipientSearch(contact.email || contact.phone);
      setNewCustomerCreatedId(contact?.contactId || CREATE_NEW_ID);
    }
  };

  const { mutateAsync: updateContactMutation } = useUpdateContactMutation(
    newContactData?.id ?? ''
  );

  const contactsQueryParams = {
    page: recipientPage,
    size: LIMIT_CONTACTS_PAGE_SIZE,
    sort: CONTACTS_SORT_KEY,
    search: recipientSearch,
  };

  const { mutateAsync: createContactMutation } = useCreateContactMutation(
    onCreateContactSuccess,
    contactsQueryParams
  );

  const { data } = useContactsQuery(
    contactsQueryParams,
    !leadsIntegrationActive
  );

  const contacts = data?.contacts ?? [];
  const contactsCount = data?.count;

  const { data: leads, refetch: refreshLeads } = useLeadsQuery(
    { size: 10, page: recipientPage, sort: '-', search: recipientSearch },
    leadsIntegrationActive
  );

  const groupsQueryParams = {
    size: LIMIT_GROUPS_PAGE_SIZE,
    sort: GROUPS_SORT_KEY,
    search: groupSearch,
    page: groupPage,
  };

  const { data: groupsData } = useQueryGroups(
    groupsQueryParams,
    !leadsIntegrationActive
  );
  const { data: leadGroupsData } = useLeadGroupsQuery(
    groupsQueryParams,
    leadsIntegrationActive
  );

  // leadsGroupData is mapped to match the GroupListItem[] type
  const groups: GroupListItem[] = leadsIntegrationActive
    ? leadGroupsData?.groups.map(group => ({
        groupId: group.leadGroupId.toString(),
        contacts: group.leads.map(lead => {
          const { leadId, ...rest } = lead;
          return {
            contactId: `${leadId}`,
            ...rest,
          };
        }),
        name: group.name,
      })) ?? []
    : groupsData?.items ?? [];

  const groupsCount =
    (leadsIntegrationActive ? leadGroupsData : groupsData)?.count ?? 0;

  const { mutateAsync } = useAddLeadMutation();

  useEffect(() => {
    if (leadsIntegrationActive) {
      refreshLeads();
    }
  }, [recipientPage, recipientSearch]);

  const isScheduleAccessible =
    checkIfScheduleAccessible(userData) && !isTrialUser;

  const openScheduleSendAndShareModal = () => {
    setShowModalScheduleSendAndShare(true);
  };

  const closeScheduleSendAndShareModal = () => {
    setShowModalScheduleSendAndShare(false);
  };

  const openConfirmDeleteScheduleModal = () => {
    setShowConfirmDeleteScheduleModal(true);
  };

  const closeConfirmDeleteScheduleModal = () => {
    setShowConfirmDeleteScheduleModal(false);
  };

  const handleUpdateSchedule = async (status?: ScheduleStatus) => {
    let updatedSchedule: Partial<Schedule> = {
      sendEmail: sendData?.sendEmail,
      sendSms: sendData?.sendSms,
      vin: sendData?.vin,
      sendVehicle: sendData?.sendVehicle,
      smsText: sendData?.smsText,
      attachments: attachments,
      emailContent: emailContent,
      overlayId: selectedOverlay?.value,
      templateId: selectedTemplate.value.toString(),
      linksetId: selectedCta?.value,
      useAutomotive:
        selectedTemplate.landingPageType === LandingPageType.AUTOMOTIVE,
      allowVideoReply: allowVideoReply,
      allowReactions: allowReactions,
      notifyWhenViewed: notify,
      skipSmsFlag: smsOnlyWithoutEmail,
      groupIds: generateSelectedGroupIdsList(),
      contactIds: generateSelectedContactIdsList(),
      leadIds: generateSelectedLeadIdsList(),
      expirationDropdownValue: expiration.id,
      expirationCustomDateValue: `${dayjs(expiration.value).format(
        'YYYY-MM-DD hh:mm A'
      )}`,
    };
    if (status) {
      updatedSchedule = { ...updatedSchedule, status };
    }
    if (editSchedule) {
      await updateSchedule({
        scheduleId: editSchedule.scheduleId,
        data: updatedSchedule,
      });
    }
  };

  const handleDeleteSchedule = async () => {
    if (editSchedule) {
      await deleteSchedule(editSchedule.scheduleId);
      closeConfirmDeleteScheduleModal();
      handleModalClose();
    }
  };

  const validateForSend = async () => {
    const vrValid =
      !!tempVideoRequest &&
      (!isAutomotiveService || !!tempVideoRequest.repairOrderNumber);
    const roEmailValid =
      !!sendData?.sendEmail &&
      (!!sendData?.email ||
        !!sendData.multipleRecipients?.some(recipient => !!recipient.email)) &&
      !tooManyEmailRecipients;

    const hasGroupAsRecipient = (sendData?.multipleRecipients || []).some(
      rec => rec.type === MultipleRecipientType.GROUP
    );
    const roSMSValid =
      !hasGroupAsRecipient &&
      !!sendData?.sendSms &&
      !!sendData?.smsText &&
      (!!sendData?.phone ||
        !!sendData.multipleRecipients?.some(recipient => !!recipient.phone)) &&
      !tooManySMSRecipients;

    const roValid =
      !!sendData &&
      (roEmailValid || roSMSValid) &&
      (!!sendData?.sendSms || !!sendData?.sendEmail);
    const isValid = (!isAutomotive || vrValid) && roValid;
    setValidForSend(isValid);
  };

  const goToPreview = () => {
    if (videoUrl)
      window.open(
        getPreviewUrl(
          videoUrl,
          isAutomotive &&
            selectedTemplate.landingPageType === LandingPageType.AUTOMOTIVE
            ? 1
            : 0
        ),
        '_blank'
      );
  };

  const handleSendClick = async () => {
    setShowFreemiumAlert(false);
    if (!sendData) return;
    if (isAutomotiveService && !sendData.sendQuote) {
      setShowModalNoQuotePrompt(true);
    } else {
      submitSend();
      // invalidate onboarding query
      checkIfFreemiumAndInvalidateForCheckList(userData, queryClient);
    }
  };

  const submitSend = async () => {
    setShowModalNoQuotePrompt(false);
    setShowLoading(true);
    if (updateCustomer && newContactData) {
      await updateContactMutation({
        contactId: newContactData.id,
        ...newContactData,
      });
    }
    if (isAutomotiveService) {
      await sendForAutomotiveService();
      setShowLoading(false);
      return;
    }
    if (isAutomotiveSales) {
      await sendForAutomotiveSales();
      setShowLoading(false);
      return;
    }
    await sendForGeneral();
    setShowLoading(false);
  };

  const handleSendSms = async (data: SendMultipleSMSRequest) => {
    if (!conversationAvailable) {
      await sendVideoSMS(data);
      return;
    }
    await Promise.all(
      data.to.map(async phoneNumber => {
        const conversation = await startConversation({
          email: null,
          phoneNumber: phoneNumber,
        } as IStartConversationBody);

        await sendConversationSmS({
          conversationId: conversation.conversationId,
          text: data.text,
          to: phoneNumber,
        });
        // TODO: refactor conversations SMS endpoint to add email receiver if needed
        await addEmailReceiver(video.id, phoneNumber, data.text);
      })
    );
  };

  const sendForGeneral = async (): Promise<void> => {
    if (!sendData || !sendData.multipleRecipients) {
      return;
    }

    // construct common send payload
    const commonSendPayload = {
      templateId: Number(selectedTemplate.value),
      linksetId: Number(selectedCta?.value) || -2,
      overlayId: selectedOverlay?.value,
      vin: '',
      notifyUser: !!notify,
      allowReactions,
      allowVideoReply,
      ...(expiration && expiration.value
        ? { expirationDate: expiration.value }
        : {}),
    };

    // construct email payload
    const emailRecipients = sendData.multipleRecipients
      .filter(recipient => sendData.sendEmail && !!recipient.email)
      .map(recipient => ({
        type: RecipientType.USER,
        id: recipient.email || '',
        text: recipient.email || '',
      }))
      .filter(Boolean);
    const sendVideoEmailPayload: SendEmailParams = {
      ...commonSendPayload,
      recipients: emailRecipients,
      subject: sendData.emailSubject || '',
      html: sendData.emailText || '',
      attachments: prepareAttachments(),
      isShared: !!isShared,
    };

    // construct sms payload
    const smsRecipients = sendData.multipleRecipients
      .filter(
        recipient =>
          sendData.sendSms &&
          sendData.smsText &&
          recipient.phone &&
          // don't send both email and sms if flag set to true and already sending email
          !(smsOnlyWithoutEmail && sendData.email)
      )
      .map(recipient => recipient.phone as string)
      .filter(Boolean);

    const sendVideoSMSPayload: SendMultipleSMSRequest = {
      ...commonSendPayload,
      to: smsRecipients,
      text: prepareTextForSMS(sendData?.smsText || ''),
      attachments: [],
    };
    let videoEmailSuccess = true;
    let videoSMSSuccess = true;
    // send video Email
    if (sendVideoEmailPayload.recipients.length) {
      try {
        await sendVideoEmail(sendVideoEmailPayload);
      } catch (error) {
        videoEmailSuccess = false;
        errorToast({ title: 'Sending Email failed, please try again later.' });
      }
    }
    // send video SMS
    if (sendVideoSMSPayload.to.length) {
      try {
        await handleSendSms(sendVideoSMSPayload);
      } catch (error) {
        videoSMSSuccess = false;
        errorToast({ title: 'Sending SMS failed, please try again later.' });
      }
    }

    if (videoEmailSuccess && videoSMSSuccess) {
      successToast({
        title: 'Your message has been queued and will be sent shortly.',
      });
      await handleDeleteSchedule();
      handleModalClose();
    }
  };

  const sendForAutomotiveSales = async (): Promise<void> => {
    if (!tempVideoRequest || !sendData) {
      errorToast({
        title:
          'An error occurred while updating your video, please try again later.',
      });
      return;
    }
    const updatedVideoRequest = await editVideoRequestMutation({
      videoRequestId: tempVideoRequest.videoRequestId,
      data: {
        ...tempVideoRequest,
        emailStatus: VideoEmailStatus.Sent,
      },
    });
    if (!updatedVideoRequest || isEmpty(updatedVideoRequest)) {
      errorToast({
        title:
          'An error occurred while updating your video, please try again later.',
      });
      return;
    }
    const salesRecipients = sendData?.multipleRecipients || [];
    let saleOffers = salesRecipients.map(recipient => ({
      ...sendData,
      vin: sendData?.sendVehicle ? sendData.vin : '',
      ...recipient,
    }));
    saleOffers = uniqBy(saleOffers, 'email');

    await sendSaleOffersEmailSMS({
      saleOffers,
      videoRequest: updatedVideoRequest,
    });
  };

  const sendSaleOffersEmailSMS = async ({
    videoRequest,
    saleOffers,
  }: {
    videoRequest: VideoRequest;
    saleOffers: Partial<SaleOfferItem>[];
  }): Promise<void> => {
    // to use for data that is same for all emails
    const firstSaleOffer = saleOffers[0];
    // construct common send payload
    const commonSendPayload = {
      vin: firstSaleOffer?.vin || '',
      templateId: Number(selectedTemplate.value),
      linksetId: Number(selectedCta?.value) || -2,
      allowVideoReply,
      allowReactions,
      notifyUser: !!notify,
      overlayId: selectedOverlay?.value,
      ...(expiration && expiration.value
        ? { expirationDate: expiration.value }
        : {}),
    };

    // construct email payload
    const emailRecipients = saleOffers
      .filter(saleOffer => saleOffer.sendEmail && saleOffer.email)
      .map(saleOffer => ({
        type: RecipientType.USER,
        id: saleOffer.email as string,
        text: saleOffer.email,
      }))
      .filter(Boolean);
    const emailText = replaceVariables({
      text: firstSaleOffer.emailText || '',
      videoRequest,
      sendData: firstSaleOffer as unknown as SendData,
      vehicleData: vehicleContent,
      userData,
    });
    const subject = replaceVariables({
      text: firstSaleOffer.emailSubject || '',
      videoRequest,
      sendData: firstSaleOffer as unknown as SendData,
      vehicleData: vehicleContent,
      userData,
    });
    const sendVideoEmailPayload: SendEmailParams = {
      ...commonSendPayload,
      recipients: emailRecipients,
      subject,
      html: emailText,
      attachments: prepareAttachments(),
      isShared: !!isShared,
    };

    // construct sms payload
    const smsRecipients = saleOffers
      .filter(
        saleOffer =>
          saleOffer.sendSms &&
          saleOffer.phone &&
          saleOffer.smsText &&
          // don't send both email and sms if flag set to true and already sending email
          !(smsOnlyWithoutEmail && saleOffer.email)
      )
      .map(saleOffer => saleOffer.phone as string)
      .filter(Boolean);
    const smsText = prepareTextForSMS(
      replaceVariables({
        text: firstSaleOffer.smsText || '',
        videoRequest,
        sendData: firstSaleOffer as unknown as SendData,
        vehicleData: vehicleContent,
        userData,
      })
    );
    const sendVideoSMSPayload: SendMultipleSMSRequest = {
      ...commonSendPayload,
      to: smsRecipients,
      text: smsText,
      attachments: [],
    };
    let videoEmailSuccess = true;
    let videoSMSSuccess = true;
    // send video Email
    if (sendVideoEmailPayload.recipients.length) {
      try {
        await sendVideoEmail(sendVideoEmailPayload);
      } catch (error) {
        videoEmailSuccess = false;
        errorToast({ title: 'Sending Email failed, please try again later.' });
      }
    }
    // send video SMS
    if (sendVideoSMSPayload.to.length) {
      try {
        await handleSendSms(sendVideoSMSPayload);
      } catch (error) {
        videoSMSSuccess = false;
        errorToast({ title: 'Sending SMS failed, please try again later.' });
      }
    }

    if (videoEmailSuccess && videoSMSSuccess) {
      successToast({
        title: 'Your message has been queued and will be sent shortly.',
      });
      await handleDeleteSchedule();
      handleModalClose();
    }
  };

  const sendForAutomotiveService = async (): Promise<void> => {
    if (!tempVideoRequest) {
      errorToast({
        title:
          'An error occurred while updating your video, please try again later.',
      });
      return;
    }
    const updatedVideoRequest = await editVideoRequestMutation({
      videoRequestId: tempVideoRequest.videoRequestId,
      data: {
        ...tempVideoRequest,
        emailStatus: VideoEmailStatus.Sent,
      },
    });
    if (!updatedVideoRequest || isEmpty(updatedVideoRequest)) {
      errorToast({
        title:
          'An error occurred while updating your video, please try again later.',
      });
      return;
    }
    const quoteItems = sendData?.sendQuote ? sendData?.quoteItems || [] : [];
    const orderOfferData = {
      ...sendData,
      quoteItems,
      vin: undefined,
    } as SendData;
    let repairOrderCreated = false;
    // create RO only when sending quote items
    if (quoteItems.length) {
      try {
        repairOrderCreated = !!(await addRepairOrder(orderOfferData));
      } catch (error) {
        return;
      }
    }
    const repairOrdersSent = await sendRepairOrderEmailSMS({
      repairOrder: orderOfferData,
      videoRequest: updatedVideoRequest,
    });
    if (repairOrderCreated && !repairOrdersSent) {
      // send to library if repair order created but sending failed to prevent duplicate repair order
      errorToast({
        title:
          'Repair order was created but sending failed, please resend repair order later.',
      });
    }
    handleModalClose();
  };

  const prepareAttachments = () => {
    return attachments.map((item: AttachedFile) => {
      return {
        name: item.name,
        type: item.type,
      };
    });
  };

  const sendRepairOrderEmailSMS = async ({
    videoRequest,
    repairOrder,
  }: {
    videoRequest: VideoRequest;
    repairOrder: Partial<RepairOrderItem>;
  }): Promise<boolean> => {
    const useAutomotive =
      isAutomotiveService ||
      selectedTemplate.landingPageType === LandingPageType.AUTOMOTIVE;

    // construct common send payload
    const commonSendPayload = {
      vin: '',
      templateId: Number(selectedTemplate.value),
      linksetId: Number(selectedCta?.value) || -2,
      allowVideoReply,
      allowReactions,
      automotive: useAutomotive ? 1 : 0,
      notifyUser: !!notify,
      overlayId: selectedOverlay?.value,
      ...(expiration && expiration.value
        ? { expirationDate: expiration.value }
        : {}),
    };
    try {
      if (repairOrder.sendEmail && repairOrder.email) {
        const emailText = replaceVariables({
          text: repairOrder.emailText || '',
          videoRequest,
          sendData: repairOrder as unknown as SendData,
          vehicleData: vehicleContent,
          userData,
        });
        const subject = replaceVariables({
          text: repairOrder.emailSubject || '',
          videoRequest,
          sendData: repairOrder as unknown as SendData,
          vehicleData: vehicleContent,
          userData,
        });
        let payload: any = {
          ...commonSendPayload,
          recipients: [
            {
              type: RecipientType.USER,
              id: repairOrder.email,
              text: repairOrder.email,
            },
          ],
          subject,
          html: emailText,
          attachments: prepareAttachments(),
          isShared: !!isShared,
        };

        await sendVideoEmail(payload);
        successToast({
          title: 'Your message has been queued and will be sent shortly.',
        });
      }

      if (repairOrder.sendSms && repairOrder.phone && repairOrder.smsText) {
        const smsText = prepareTextForSMS(
          replaceVariables({
            text: repairOrder.smsText || '',
            videoRequest,
            sendData: repairOrder as unknown as SendData,
            vehicleData: vehicleContent,
            userData,
          })
        );
        const sendVideoSMSPayload: SendMultipleSMSRequest = {
          ...commonSendPayload,
          to: [repairOrder.phone],
          text: smsText,
          attachments: [],
        };
        await handleSendSms(sendVideoSMSPayload);
      }
      return true;
    } catch (error) {
      errorToast({
        title:
          'An error occurred preparing your message, please try again later!',
      });
      return false;
    }
  };

  const fetchRecipientsDebounced = useCallback(
    debounce(async (search: string) => {
      setRecipientSearch(search);
      setRecipientPage(0);
      if (leadsIntegrationActive) {
        refreshLeads();
      }
      if (enableGroupSend) {
        setGroupSearch(search);
        setGroupPage(0);
      }
    }, 500),
    []
  );

  const onRecipientScrollToBottom = () => {
    let fetchedContactsCount = 0;
    let fetchedLeadsCount = 0;
    let fetchedGroupsCount = 0;
    const newPage = recipientPage + 1;
    setRecipientPage(newPage);
    for (const recipient of recipientOptions) {
      if (recipient.type === MultipleRecipientType.GROUP) {
        fetchedGroupsCount++;
      }
      if (recipient.type === MultipleRecipientType.LEAD) {
        fetchedLeadsCount++;
      }
      if (recipient.type === MultipleRecipientType.CONTACT) {
        fetchedContactsCount++;
      }
    }
    if (leadsIntegrationActive && fetchedLeadsCount < (leads?.count || 0)) {
      refreshLeads();
    }
    if (
      !leadsIntegrationActive &&
      contactsCount &&
      fetchedContactsCount < contactsCount
    ) {
      setRecipientPage(newPage);
    }
    if (enableGroupSend && fetchedGroupsCount < groupsCount) {
      setGroupPage(newPage);
      setGroupSearch(recipientSearch);
    }
  };

  const handleRecipientInputChange = (inputValue: string) => {
    if (selectedRecipientIds.includes(CREATE_NEW_ID) || inputValue === '') {
      return;
    }
    fetchRecipientsDebounced(inputValue);
    const phone = PHONE_REGEX.test(inputValue) ? inputValue : '';
    const email = EMAIL_REGEX.test(inputValue) ? inputValue : '';
    const name = phone || email ? '' : inputValue;
    setNewCustomerData({
      phone: phone,
      email: email,
      firstName: name,
      lastName: '',
      contactId: '',
      companyName: '',
    });
  };

  const handleDeleteQuote = () => {
    setRequiredQuoteItems([]);
    setOptionalQuoteItems([]);
    setShowModalDeleteQuotePrompt(false);
  };

  const onChangeRecipient = (ids: string[]) => {
    setSelectedRecipientIds(ids);
  };

  const handleNewCustomerSubmit = async () => {
    try {
      // check if exists only for contacts
      if (
        !leadsIntegrationActive &&
        newCustomerData?.email &&
        newCustomerData.email.trim() !== ''
      ) {
        const exist = await getContacts({ search: newCustomerData?.email });
        if (exist.count !== 0) {
          errorToast({ title: `Customer already exists!` });
          return;
        }
      }
      let newCustomer;
      if (!leadsIntegrationActive && newCustomerData) {
        const { contactId: _, ...filteredCustomerData } = newCustomerData;
        await createContactMutation({
          ...filteredCustomerData,
          groups: [],
        });
      }
      // leads
      if (leadsIntegrationActive) {
        const leadData = {
          email: newCustomerData?.email,
          firstName: newCustomerData?.firstName,
          lastName: newCustomerData?.lastName,
          phone: newCustomerData?.phone,
        };
        newCustomer = await mutateAsync(leadData);
        setNewCustomerCreatedId(newCustomer?.leadId || CREATE_NEW_ID);
        setRecipientPage(0);
        refreshLeads();
      }
      setNewCustomerData(undefined);
    } catch (error) {
      showError(error);
    }
  };

  const handleCancelNewCustomer = async () => {
    setNewCustomerCreatedId(CREATE_NEW_ID);
    setSelectedRecipientIds(prev => prev.filter(id => id !== CREATE_NEW_ID));
  };

  const handleChangeVehicle = () => {
    setVehicleContent(undefined);
    changeVin('');
  };

  const constructAllRecipients = () => {
    const leadsList = Object.values(leads?.leads || []);
    return constructRecipientsFromLeadsContactsGroups(
      leadsIntegrationActive,
      leadsList,
      contacts,
      enableGroupSend,
      groups
    );
  };

  const constructSelectedRecipients = (
    selectedIds: (string | number)[],
    all: Recipient[]
  ) => {
    const allDictionary: Dictionary<Recipient> = keyBy(all, 'id');
    const allRecipients: Dictionary<Recipient> = {};
    const recipientsWithPhone: Dictionary<Recipient> = {};
    const recipientsWithEmail: Dictionary<Recipient> = {};

    // all selected recipients
    selectedIds.forEach(id => {
      if (allDictionary[id]) {
        allRecipients[id] = allDictionary[id];
      }
      if (allDictionary[id]?.email) {
        recipientsWithEmail[id] = allDictionary[id];
      }
      if (allDictionary[id]?.phone) {
        recipientsWithPhone[id] = allDictionary[id];
      }
    });

    // all selected recipients from groups
    const allSelectedGroups = selectedIds.filter(
      id => allDictionary[id]?.type === MultipleRecipientType.GROUP
    );
    allSelectedGroups.forEach(id => {
      const groupMultiRecipients = allDictionary[id].multipleRecipients || [];
      groupMultiRecipients.forEach(rec => (allRecipients[rec.id] = rec));
      groupMultiRecipients
        .filter(rec => rec.email)
        .forEach(rec => (recipientsWithEmail[rec.id] = rec));
      groupMultiRecipients
        .filter(rec => rec.phone)
        .forEach(rec => (recipientsWithPhone[rec.id] = rec));
    });

    return {
      allSelected: Object.values(allRecipients),
      recipientsWithEmail,
      recipientsWithPhone,
    };
  };

  const generateSelectedGroupIdsList = () => {
    if (enableGroupSend && selectedRecipient && selectedRecipient.length) {
      const groups = selectedRecipient.filter((recipient: Recipient) =>
        recipient.id.toString().includes('group')
      );
      const groupIdsList = groups.map((recipient: Recipient) =>
        toNumber(recipient.id.toString().replace('group-', ''))
      );
      return groupIdsList;
    }
    return [];
  };

  const generateSelectedContactIdsList = () => {
    if (leadsIntegrationActive) {
      return [];
    }
    if (selectedRecipient && selectedRecipient.length) {
      const contacts = selectedRecipient.filter(
        (recipient: Recipient) => !recipient.id.toString().includes('group')
      );
      const contactIdsList = contacts.map((recipient: Recipient) =>
        toNumber(recipient.id)
      );
      return contactIdsList;
    }
    return [];
  };

  useEffect(() => {
    const { options, autoSelectedOption } = prepareLinksetCTAOptions({
      userData,
      linksetsResponse: linksets,
    });
    if (autoSelectedOption) {
      setSelectedCta(autoSelectedOption);
    }
    setCtaOptions(options);
  }, [linksets]);

  useEffect(() => {
    const { options, autoSelectedOption } =
      prepareWebsiteOverlayOptions(overlays);
    if (autoSelectedOption) {
      setSelectedOverlay(autoSelectedOption);
    }
    setOverlayOptions(options);
  }, [overlays]);

  useEffect(() => {
    if (!templates) {
      return;
    }
    const { autoSelectedOption, options } = prepareTemplateLandingPageOptions({
      userData,
      templatesResponse: templates,
      sendVehicle,
      sendQuote,
    });
    setSelectedTemplate(autoSelectedOption);
    setTemplateOptions(options);
  }, [sendVehicle, sendQuote, templates]);

  const generateSelectedLeadIdsList = () => {
    if (leadsIntegrationActive) {
      return selectedRecipientIds.map((recipientId: string | number) =>
        toNumber(recipientId)
      );
    }
    return [];
  };

  useEffect(() => {
    if (!editSchedule) {
      return;
    }
    if (editSchedule?.linksetId) {
      const cta = ctaOptions.filter(
        (ctaOption: any) =>
          ctaOption.value.toString() === editSchedule.linksetId
      );
      if (cta.length > 0) {
        setSelectedCta(cta[0]);
      }
    }
  }, [ctaOptions]);

  useEffect(() => {
    if (!editSchedule) {
      return;
    }
    if (editSchedule?.templateId && editSchedule.templateId !== '0') {
      const template = templateOptions.filter(
        (templateOption: any) =>
          templateOption.value.toString() === editSchedule.templateId
      );
      if (template.length > 0) {
        setSelectedTemplate(template[0]);
      }
    }
  }, [templateOptions]);

  useEffect(() => {
    if (!editSchedule) {
      return;
    }
    if (editSchedule?.overlayId) {
      const overlay = overlays.websiteUrls.filter(
        (overlayOption: any) =>
          overlayOption.value?.toString() === editSchedule.overlayId
      );
      if (overlay.length > 0) {
        setSelectedOverlay(overlay[0]);
      }
    }
  }, [overlays.websiteUrls]);

  useEffect(() => {
    setTempVideoRequest(tempVideoRequest);
  }, [tempVideoRequest]);

  useEffect(() => {
    if (isAutomotive && !tempVideoRequest?.videoId) {
      return;
    }
    changeQuickshareParams({
      templateId: parseInt(selectedTemplate.value.toString()),
      vin,
      linksetId: selectedCta?.value || '-1',
      overlayId: selectedOverlay?.value || '',
      allowReactions,
      allowVideoReply,
      expirationDate: expiration?.value,
      useAutomotive:
        isAutomotiveService ||
        selectedTemplate.landingPageType === LandingPageType.AUTOMOTIVE,
    });
  }, [
    tempVideoRequest,
    selectedCta,
    selectedTemplate,
    selectedOverlay,
    allowVideoReply,
    allowReactions,
    vin,
    expiration?.value,
  ]);

  useEffect(() => {
    if (!tempVideoRequest || !sendData) {
      return;
    }
    savePreviewCookie({
      quoteItems: sendData.sendQuote
        ? sendData.quoteItems
        : ([] as QuoteItem[]),
    });
  }, [tempVideoRequest, sendData?.sendQuote, sendData?.quoteItems]);

  useEffect(() => {
    validateForSend();
  }, [sendData, tempVideoRequest]);

  useEffect(() => {
    // if leads integration active, fetch leads, otherwise fetch contacts
    if (leadsIntegrationActive) {
      refreshLeads();
    }
    if (!leadsIntegrationActive) {
      setRecipientPage(0);
    }
    // if enableGroupSend, fetch groups
    if (enableGroupSend) {
      setGroupSearch('');
      setGroupPage(0);
    }
  }, [leadsIntegrationActive]);

  useEffect(() => {
    if (newCustomerCreatedId !== CREATE_NEW_ID) {
      if (!enableMultipleRecipients) {
        setSelectedRecipientIds([newCustomerCreatedId]);
      }
      if (enableMultipleRecipients) {
        setSelectedRecipientIds(prev => [
          ...prev.filter(id => id !== CREATE_NEW_ID),
          newCustomerCreatedId,
        ]);
      }
    }
    setNewCustomerCreatedId(CREATE_NEW_ID);
  }, [newCustomerCreatedId]);

  useEffect(() => {
    // set create option and data for new customer
    if (
      !selectedRecipientIds.length ||
      selectedRecipientIds.includes(CREATE_NEW_ID)
    ) {
      setSelectedRecipient(undefined);
      return;
    }
    const selectedContact = recipientOptions.find(
      recipient => recipient.id === selectedRecipientIds[0]
    );
    setNewFirstName(selectedContact?.firstName || '');
    setNewLastName(selectedContact?.lastName || '');
    setNewFirstNameValid(!!selectedContact?.firstName);
  }, [selectedRecipientIds]);

  useEffect(() => {
    if (selectedRecipient && selectedRecipient.length) {
      const recipientCheckFirstName =
        !!selectedRecipient[0].firstName?.trim().length;
      setRecipientHasFirstName(recipientCheckFirstName);

      const recipientCheckLastName =
        !!selectedRecipient[0].lastName?.trim().length;
      setRecipientHasLastName(recipientCheckLastName);

      const recipientCheckEmail = !!selectedRecipient[0].email?.trim().length;
      setRecipientHasEmail(recipientCheckEmail);
      const sendEmailFlagNewValue = recipientCheckEmail || newEmailValid;
      // do not allow auto-enable of send email flag when editing scheduled Email/SMS
      if (editSchedule && sendEmailFlagNewValue) {
        return;
      }
      setSendEmailFlag(sendEmailFlagNewValue);
      return;
    }
    setRecipientHasFirstName(false);
    setRecipientHasLastName(false);
    setRecipientHasEmail(false);
    setSendEmailFlag(false);
  }, [selectedRecipient, newEmail, enableMultipleRecipients]);

  useEffect(() => {
    if (selectedRecipient && selectedRecipient.length) {
      // if only sending to contacts without email check if there are any left with phone
      const recipientCheckPhone = selectedRecipient
        .filter(recipient => !smsOnlyWithoutEmail || !recipient.email)
        .some(recipient => !!recipient.phone);
      setRecipientHasPhone(recipientCheckPhone);

      const sendSmsFlagNewValue =
        !!prepareTextForSMS(smsContent.body).length &&
        (recipientCheckPhone || newPhoneValid) &&
        !isSmsSwitchDisabled;

      // do not allow auto-disable of send sms flag when editing scheduled Email/SMS during first render
      if (editSchedule && !firstLoadPassed) {
        setFirstLoadPassed(true);
        return;
      }
      // do not allow auto-enable of send sms flag when editing scheduled Email/SMS
      if (editSchedule && sendSmsFlagNewValue) {
        return;
      }

      setSendSmsFlag(sendSmsFlagNewValue);
      return;
    }
    setRecipientHasPhone(false);
    setSendSmsFlag(false);
  }, [selectedRecipient, newPhone, smsContent, smsOnlyWithoutEmail]);

  useEffect(() => {
    if (!sendEmailFlag) {
      // flag toggle is hidden when not sending email, set flag to false when not sending email
      setSmsOnlyWithoutEmail(false);
    }
  }, [sendEmailFlag]);

  useEffect(() => {
    const roPhone = selectedRecipient?.[0]?.phone || newPhone;
    const roEmail = selectedRecipient?.[0]?.email || newEmail;
    const roFirstName = selectedRecipient?.[0]?.firstName || newFirstName;
    const roLastName = selectedRecipient?.[0]?.lastName || newLastName;

    const orderOfferData = {
      videoId: video.id,
      repairOrderNumber: tempVideoRequest?.repairOrderNumber || '',
      status: RepairOrderStatus.Sent,
      firstName: roFirstName,
      lastName: roLastName,
      phone: roPhone,
      email: roEmail,
      multipleRecipients: selectedRecipient,
      sendEmail: sendEmailFlag,
      sendQuote: sendQuote,
      sendVehicle: sendVehicle,
      sendSms: sendSmsFlag,
      emailSubject: emailContent.subject,
      emailText: emailContent.body,
      smsText: smsContent.body,
      quoteItems: requiredQuoteItems.concat(optionalQuoteItems),
      vin: vin,
      expirationDate: expiration.value,
    };
    setSendData(orderOfferData);
  }, [
    isAutomotive,
    selectedRecipient,
    sendEmailFlag,
    emailContent,
    sendSmsFlag,
    smsContent,
    newEmail,
    newPhone,
    sendQuote,
    tempVideoRequest,
    requiredQuoteItems,
    optionalQuoteItems,
    newFirstName,
    newLastName,
    vin,
    sendVehicle,
  ]);

  useEffect(() => {
    setSendQuote(!!requiredQuoteItems.length || !!optionalQuoteItems.length);
  }, [requiredQuoteItems, optionalQuoteItems]);

  useEffect(() => {
    setSendVehicle(!isEmpty(vehicleContent));
  }, [vehicleContent]);

  useEffect(() => {
    const selected = selectedRecipient?.[0];
    if (!selected) {
      return;
    }
    const roEmail =
      updateCustomerEmail && newEmailValid ? newEmail : selected.email;
    const roPhone =
      updateCustomerPhone && newPhoneValid ? newPhone : selected.phone;
    const roFirstName =
      updateCustomerName && newFirstNameValid
        ? newFirstName
        : selected.firstName;
    const roLastName =
      updateCustomerName && newLastName ? newLastName : selected.lastName;
    setUpdateCustomer(
      updateCustomerEmail || updateCustomerPhone || updateCustomerName
    );
    setNewContactData({
      ...selected,
      email: roEmail,
      phone: roPhone,
      firstName: roFirstName,
      lastName: roLastName,
    });
  }, [
    selectedRecipient,
    updateCustomerEmail,
    updateCustomerPhone,
    updateCustomerName,
    newPhone,
    newEmail,
    newFirstName,
    newLastName,
  ]);

  useEffect(() => {
    const { allSelected, recipientsWithEmail, recipientsWithPhone } =
      constructSelectedRecipients(selectedRecipientIds, recipientOptions);

    setSelectedRecipientsWithEmail(recipientsWithEmail);

    // disable group sms
    const hasGroups = allSelected.some((recipient: Recipient) =>
      recipient.id.toString().includes('group')
    );
    setIsGroupSelected(hasGroups);
    setSelectedRecipientsWithPhone(hasGroups ? {} : recipientsWithPhone);
    setSelectedRecipient(allSelected);
    if (!enableMultipleRecipients) {
      return;
    }
    const tooManyEmail =
      Object.values(recipientsWithEmail).length > emailsSentLimit;
    setTooManyEmailRecipients(tooManyEmail);
    if (tooManyEmail) {
      setSendEmailFlag(false);
    }
    const tooManySMS =
      Object.values(recipientsWithPhone).length > MAX_PHONE_RECIPIENTS;
    if (tooManySMS) {
      setSendSmsFlag(false);
    }
    setTooManySMSRecipients(tooManySMS);
  }, [selectedRecipientIds]);

  useEffect(() => {
    // Add newRecipients at the start to get latest values in the dropdown
    setRecipientOptions(prev => {
      const newRecipients = constructAllRecipients();
      return uniqBy([...newRecipients, ...prev], 'id');
    });
  }, [leadsIntegrationActive, leads, data, groupsData, leadGroupsData]);

  useEffect(() => {
    if (vin) {
      return;
    }
    changeVin(
      editSchedule ? editSchedule?.vin || '' : tempVideoRequest?.vin || ''
    );
  }, [editSchedule?.vin, tempVideoRequest?.vin]);

  const clearVehicleSelection = () => {
    setVehicleContent(undefined);
    changeVin('');
  };

  const recipientsWithBothPhoneAndEmail = Object.values(
    selectedRecipientsWithPhone
  ).filter(recipient => !!selectedRecipientsWithEmail[recipient.id]);
  const allHaveBothEmailAndPhone =
    recipientsWithBothPhoneAndEmail.length ===
    Object.values(selectedRecipientsWithPhone).length;

  // show flag if multiple enabled, if also sending email and if there are contacts with both email and phone to whom this flag applies
  const enableSmsOnlyWithoutEmail =
    enableMultipleRecipients &&
    sendEmailFlag &&
    !!recipientsWithBothPhoneAndEmail.length;

  const isEmailSwitchDisabled =
    !selectedRecipientIds.length ||
    selectedRecipientIds.includes(CREATE_NEW_ID) ||
    (!enableMultipleRecipients && !recipientHasEmail && !newEmailValid) ||
    (enableMultipleRecipients && isEmpty(selectedRecipientsWithEmail)) ||
    tooManyEmailRecipients;

  // sending sms to group is not allowed
  const smsRecipients = isGroupSelected ? [] : selectedRecipientIds;

  const isSmsSwitchDisabled =
    !smsRecipients.length ||
    (!!recipientsWithBothPhoneAndEmail.length &&
      allHaveBothEmailAndPhone &&
      smsOnlyWithoutEmail) ||
    (!enableMultipleRecipients && !recipientHasPhone && !newPhoneValid) ||
    (enableMultipleRecipients && isEmpty(selectedRecipientsWithPhone)) ||
    !prepareTextForSMS(smsContent.body).length ||
    tooManySMSRecipients;

  // enable adding missing email if single recipient mode and selected has no email
  const enableAddingMissingEmail =
    !enableMultipleRecipients &&
    !!selectedRecipientIds.length &&
    !selectedRecipientIds.includes(CREATE_NEW_ID) &&
    !recipientHasEmail;

  // enable adding missing phone if single recipient mode and selected has no phone
  const enableAddingMissingPhone =
    !enableMultipleRecipients &&
    !!selectedRecipientIds.length &&
    !selectedRecipientIds.includes(CREATE_NEW_ID) &&
    !recipientHasPhone;

  // enable adding missing name if single recipient mode and selected has no name
  const enableAddingMissingName =
    !enableMultipleRecipients &&
    selectedRecipientIds.length === 1 &&
    !selectedRecipientIds.includes(CREATE_NEW_ID) &&
    (!recipientHasFirstName || !recipientHasLastName);

  const smsDisabledMessage = isGroupSelected
    ? 'Sending SMS to groups is not available'
    : tooManySMSRecipients
      ? `Too many SMS recipients (max ${MAX_PHONE_RECIPIENTS})`
      : !isEmpty(selectedRecipientsWithPhone) &&
          (!smsOnlyWithoutEmail || !allHaveBothEmailAndPhone)
        ? 'Add text below to enable the SMS toggle'
        : 'Add recipient with a phone number to send SMS';

  const emailDisabledMessage = tooManyEmailRecipients
    ? `Too many email recipients (max ${emailsSentLimit})`
    : 'Add email address to send Email';

  let sendButtonText = 'Send Email';
  let scheduleModalTitle = 'Schedule Email';
  let deleteButtonText = 'Delete Email';
  let deleteScheduleModalTextExtra = 'email';

  if (sendData) {
    if (sendData.sendSms && !sendData.sendEmail) {
      sendButtonText = 'Send SMS';
      scheduleModalTitle = 'Schedule SMS';
      deleteButtonText = 'Delete SMS';
      deleteScheduleModalTextExtra = 'SMS';
    }

    if (sendData.sendEmail && sendData.sendSms) {
      sendButtonText = 'Send Email & SMS';
      scheduleModalTitle = 'Schedule Email & SMS';
      deleteButtonText = 'Delete Email & SMS';
      deleteScheduleModalTextExtra = 'email & SMS';
    }
  }
  const smsDisabled = !isSmsFeatureEnabled || !isSmsEnabledForCustomer;
  const overlayValid = checkIfOverlayValid(
    selectedTemplate?.value || '',
    selectedOverlay?.value || ''
  );
  const smsFeatureDisabledMessage = !isSmsFeatureEnabled ? (
    <ProductInfoTooltip nextPlan={PackageNameLabel.TEAMS}>
      <MdLock size={18} /> Sending SMS not available.
    </ProductInfoTooltip>
  ) : (
    noSmsMsg
  );

  const totalSelectedRecipients = countTotalEmailRecipients(
    selectedRecipientIds,
    recipientOptions
  );

  const updatedEmailLimit = emailLimit + totalSelectedRecipients || 0;

  const canSendTrialEmails =
    !isTrialUser ||
    canSendMoreTrialEmails(
      sendData?.multipleRecipients?.length || 0,
      Number(userData.trialEmailsSent)
    );
  const isSendButtonDisabled =
    !validForSend ||
    shareUrlLoading ||
    !overlayValid ||
    !canSendTrialEmails ||
    updatedEmailLimit > emailsSentLimit;

  if (showLoading) {
    return <LoadingIndicator isLoading={true} height='300px' />;
  }

  return (
    <TabWrapper display={activeTab === tabs.send.value ? 'block' : 'none'}>
      <ModalHeader>
        <HeaderSide>
          <LinkWrapper>
            <LinkBtn onClick={handleModalClose}>
              <MdClose size='18' />
              <span>Close</span>
            </LinkBtn>
          </LinkWrapper>
        </HeaderSide>
        <HeaderCenter>
          {!editSchedule && (
            <ButtonPillSwitch
              defaultValue={activeTab}
              values={tabs}
              onChange={newTab => setActiveTab(newTab)}
            />
          )}
          {editSchedule && (
            <EditScheduleModalTitle>
              {scheduleModalTitle}
            </EditScheduleModalTitle>
          )}
        </HeaderCenter>
        <HeaderSide>
          {editSchedule && (
            <Actions>
              <Button
                disabled={
                  !validForSend ||
                  shareUrlLoading ||
                  isLoadingDeleteSchedule ||
                  isLoadingUpdateSchedule
                }
                variant='destructive'
                text={deleteButtonText}
                onClick={openConfirmDeleteScheduleModal}
              />
              <Button
                disabled={
                  !validForSend ||
                  shareUrlLoading ||
                  isLoadingDeleteSchedule ||
                  isLoadingUpdateSchedule ||
                  updatedEmailLimit > emailsSentLimit
                }
                text='Send Now'
                variant='secondary'
                onClick={async () => {
                  await handleUpdateSchedule(ScheduleStatus.SENT);
                  handleSendClick();
                }}
              />
              <Button
                disabled={false}
                text='Edit Schedule'
                variant='secondary'
                onClick={async () => {
                  setShowModalScheduleSendAndShare(true);
                }}
              />
              <Button
                disabled={
                  !validForSend ||
                  shareUrlLoading ||
                  isLoadingDeleteSchedule ||
                  isLoadingUpdateSchedule
                }
                variant='primary'
                text='Save Schedule'
                icon={<MdSave />}
                onClick={async () => {
                  await handleUpdateSchedule();
                  handleModalClose();
                }}
              />
            </Actions>
          )}

          {!editSchedule && (
            <Actions>
              <Button
                variant='secondary'
                disabled={!validForSend || shareUrlLoading || !overlayValid}
                text='Preview'
                onClick={() => goToPreview()}
                icon={<BiLinkExternal />}
              />
              {isScheduleAccessible && (
                <>
                  {isSchedulesEnabled && (
                    <Button
                      variant='secondary'
                      disabled={
                        !validForSend || shareUrlLoading || !overlayValid
                      }
                      text='Schedule'
                      onClick={() => openScheduleSendAndShareModal()}
                      icon={<MdScheduleSend />}
                    />
                  )}
                  {!isSchedulesEnabled && (
                    <ProductInfoTooltip
                      width={'fit-content'}
                      style={{
                        left: '-30px',
                        top: '40px',
                      }}
                      productFeatureId={productFeature.SCHEDULES}
                      nextPlan={PackageNameLabel.TEAMS}
                    >
                      <Button
                        variant='secondary'
                        disabled={true}
                        text='Schedule'
                        icon={<IoMdLock />}
                      />
                    </ProductInfoTooltip>
                  )}
                </>
              )}
              <Button
                disabled={isSendButtonDisabled}
                text={sendButtonText}
                onClick={() => {
                  handleSendClick();
                }}
                icon={
                  <MdOutlineEmail color={themes.colors.white[100]} size={18} />
                }
                data-cy={
                  selectors.libraryPage.sendAndShareModal.sendEmailButton
                }
              />
            </Actions>
          )}
        </HeaderSide>
      </ModalHeader>
      <ModalBody>
        <ContentWrapper>
          <Main>
            {isTrialUser && (
              <InformationBox isDanger={!canSendTrialEmails}>
                <AiFillInfoCircle
                  size={24}
                  color={
                    canSendTrialEmails
                      ? theme.palette.covideoBlue60
                      : theme.palette.red60
                  }
                />
                <TrialTextWrapper>
                  {canSendTrialEmails
                    ? `During your free trial, you can send emails to a total of ${MAX_TRIAL_EMAILS} recipients.`
                    : `You have reached the limit of ${MAX_TRIAL_EMAILS} emails. Upgrade your Covideo account for unlimited recording, sending, and sharing!`}
                </TrialTextWrapper>
              </InformationBox>
            )}
            {editSchedule && (
              <EditScheduleDeliveryTimeCard
                deliveryTime={editSchedule.deliveryTime}
              />
            )}
            <RecipientSearch
              title='Recipient'
              multiple={enableMultipleRecipients}
              onChangeRecipient={onChangeRecipient}
              selectedRecipientIds={selectedRecipientIds}
              onInputChange={handleRecipientInputChange}
              recipients={recipientOptions}
              showExpandedContent={
                selectedRecipientIds.includes(CREATE_NEW_ID) ||
                (!enableMultipleRecipients &&
                  (!recipientHasFirstName || !recipientHasLastName))
              }
              emailLimit={updatedEmailLimit}
              createNewOption={true}
              onMenuScrollToBottom={onRecipientScrollToBottom}
              expandedContent={
                <>
                  {enableAddingMissingName && (
                    <AddMissingName
                      name={newFirstName}
                      onChangeName={setNewFirstName}
                      lastName={newLastName}
                      onChangeLastName={setNewLastName}
                      isChecked={updateCustomerName}
                      setIsChecked={setUpdateCustomerName}
                      checkId={'check-update-name'}
                      nameValid={newFirstNameValid}
                      setNameValid={setNewFirstNameValid}
                      updatable={
                        !leadsIntegrationActive && !!selectedRecipientIds.length
                      }
                    />
                  )}
                  {selectedRecipientIds.includes(CREATE_NEW_ID) && (
                    <AddCustomer
                      handleCancel={handleCancelNewCustomer}
                      handleSubmit={handleNewCustomerSubmit}
                      newCustomerData={newCustomerData}
                      changeCustomerData={setNewCustomerData}
                    />
                  )}
                </>
              }
            />
            <LimitExceedInfoBox emailLimit={updatedEmailLimit} />
            {isVehicleCardEnabled && (
              <CardWithSwitch
                switchId={'switch-vehicle'}
                switchIsOn={sendVehicle}
                handleToggle={() => {
                  setSendVehicle(prevSendVehicle => {
                    if (prevSendVehicle) {
                      clearVehicleSelection();
                    }
                    return !prevSendVehicle;
                  });
                }}
                title={'Vehicle'}
                data-cy={selectors.libraryPage.sendAndShareModal.vehicleSwitch}
                text={'Add a vehicle to send a vehicle'}
                switchDisabled={isEmpty(vehicleContent)}
                expandedDefault={true}
                expandedContent={
                  !isEmpty(vehicleContent) ? (
                    <VehiclePropertiesGrid
                      vehicle={vehicleContent || ({} as InventoryItem)}
                      onChangeVehicle={handleChangeVehicle}
                    />
                  ) : (
                    <VehicleSearch
                      onChangeVehicle={(item: InventoryItem) => {
                        changeVin(item?.vin || '');
                        setVehicleContent(item);
                      }}
                      selectedVin={vin}
                      fetchSold={false}
                      menuZindex={1000}
                    />
                  )
                }
              />
            )}
            <CardWithSwitch
              switchId={'switch-1'}
              switchIsOn={!isEmailSwitchDisabled && sendEmailFlag}
              handleToggle={() => {
                setSendEmailFlag(prevSendEmailFlag => !prevSendEmailFlag);
              }}
              title={'Email'}
              text={emailDisabledMessage}
              summary={emailContent}
              switchDisabled={isEmailSwitchDisabled}
              badge={
                enableMultipleRecipients && (
                  <RecipientsBadge
                    recipientsWithType={selectedRecipientsWithEmail}
                    recipientsOther={selectedRecipientsWithPhone}
                    type={RecipientTypeEnum.EMAIL}
                  />
                )
              }
              expandedDefault={true}
              expandedContent={
                <>
                  {enableAddingMissingEmail && (
                    <AddMissingInfo
                      inputType='email'
                      inputValue={newEmail}
                      onChangeInputValue={setNewEmail}
                      isChecked={updateCustomerEmail}
                      setIsChecked={setUpdateCustomerEmail}
                      checkId={'check-update-email'}
                      inputPlaceholder={'Enter email address'}
                      inputValid={newEmailValid}
                      setInputValid={setNewEmailValid}
                      updatable={!leadsIntegrationActive}
                      skipInitialValidation={true}
                    />
                  )}
                  <div>
                    <EmailBuilder
                      video={video}
                      emailContent={emailContent}
                      setEmailContent={setEmailContent}
                      changeElementBeingEdited={setElementBeingEditedId}
                    />
                    <Attachments
                      attachments={attachments}
                      setAttachments={setAttachments}
                      attachmentUploadStatus={attachmentUploadStatus}
                      setAttachmentUploadStatus={setAttachmentUploadStatus}
                    />
                  </div>
                </>
              }
            />
            {whitelabel.name === WHITELABEL_NAME.COVIDEO && !smsDisabled && (
              <CardWithSwitch
                switchId={'switch-2'}
                switchIsOn={!isSmsSwitchDisabled && sendSmsFlag}
                handleToggle={() => {
                  if (isSmsSwitchDisabled) {
                    return;
                  }
                  setSendSmsFlag(prevSendSmsFlag => !prevSendSmsFlag);
                }}
                title={'SMS'}
                text={smsDisabledMessage}
                summary={smsContent}
                switchDisabled={isSmsSwitchDisabled}
                includeTooltip={true}
                badge={
                  enableMultipleRecipients && (
                    <RecipientsBadge
                      recipientsWithType={selectedRecipientsWithPhone}
                      recipientsOther={selectedRecipientsWithEmail}
                      type={RecipientTypeEnum.SMS}
                      onlyWithoutOther={smsOnlyWithoutEmail}
                    />
                  )
                }
                expandedDefault={true}
                expandedContent={
                  smsDisabled ? (
                    smsFeatureDisabledMessage
                  ) : (
                    <>
                      {enableAddingMissingPhone && (
                        <AddMissingInfo
                          inputType='phone'
                          inputValue={newPhone}
                          onChangeInputValue={setNewPhone}
                          isChecked={updateCustomerPhone}
                          setIsChecked={setUpdateCustomerPhone}
                          checkId={'check-update-phone'}
                          inputPlaceholder={'xxx-xxx-xxxx'}
                          inputValid={newPhoneValid}
                          setInputValid={setNewPhoneValid}
                          updatable={!leadsIntegrationActive}
                          isSwitchDisabled={isSmsSwitchDisabled}
                          skipInitialValidation={true}
                        />
                      )}
                      {enableSmsOnlyWithoutEmail && (
                        <CheckboxInputWrapper>
                          <CheckboxInput
                            id={`check-sms`}
                            width='24px'
                            blueCheck={true}
                            checked={smsOnlyWithoutEmail}
                            onChange={(e: React.SyntheticEvent) => {
                              const { checked } = e.target as HTMLInputElement;
                              setSmsOnlyWithoutEmail(checked);
                            }}
                          />
                          <CheckboxLabel htmlFor={`check-sms`}>
                            Don’t send SMS to{' '}
                            {recipientsWithBothPhoneAndEmail.length} matching
                            email recipients
                          </CheckboxLabel>
                        </CheckboxInputWrapper>
                      )}
                      <SmsEditor
                        smsContent={smsContent}
                        setSmsContent={setSmsContent}
                        showMagicButton={true}
                        magicBtnLabel={'AI Assist'}
                        magicHoverPopup={<NotSurePopup />}
                        defaultVin={vin || ''}
                      />
                    </>
                  )
                }
              />
            )}

            {isQoteCardEnabled && !showQuoteCard && (
              <CardAddQuote handleClick={() => setShowQuoteCard(true)} />
            )}
            {isQoteCardEnabled && showQuoteCard && (
              <>
                <QuoteCard
                  switchId={'switch-3'}
                  switchIsOn={sendQuote}
                  handleToggle={() => {
                    setSendQuote((prevSendQuote: any) => !prevSendQuote);
                  }}
                  title={'Quote'}
                  requiredQuoteItems={requiredQuoteItems}
                  optionalQuoteItems={optionalQuoteItems}
                  setRequiredQuoteItems={setRequiredQuoteItems}
                  setOptionalQuoteItems={setOptionalQuoteItems}
                  handleDeleteQuote={() => setShowModalDeleteQuotePrompt(true)}
                />
              </>
            )}
            {showModalDeleteQuotePrompt && (
              <ModalDeleteQuotePrompt
                onClickSecondaryButton={() =>
                  setShowModalDeleteQuotePrompt(false)
                }
                onClickPrimaryButton={() => handleDeleteQuote()}
                handleModalClose={() => setShowModalDeleteQuotePrompt(false)}
              />
            )}
          </Main>
          {elementBeingEditedId === BUILDER_IDS.EMPTY && (
            <Side>
              <VideoTitleAndThumbnail video={video} marginBottom='24px' />
              <DeletionInfo
                bgColor={theme.palette.covideoBlue100}
                fontColor={theme.palette.white}
                videoId={video.id}
                dateOfCreation={dateOfCreation}
              />
              {isExpirationActivated && !isQuotibleTemplateSelected && (
                <>
                  <Label>Link Expiration</Label>
                  <Dropdown
                    options={
                      !isCustomLinkExp(expiration.id)
                        ? dropdownItems
                        : dropdownItems.map(element =>
                            isCustomLinkExp(element.id)
                              ? {
                                  ...element,
                                  label: `Custom (${dayjs(
                                    expiration.value?.toString()
                                  ).format(
                                    `${STANDARD_DATE_FORMAT} hh:mm A`
                                  )})...`,
                                }
                              : element
                          )
                    }
                    value={expiration}
                    onChange={(option: any) => {
                      isCustomLinkExp(option.id)
                        ? setCustomOpened(true)
                        : setExpiration(option);
                    }}
                    creatable={false}
                    disabled={dropdownItems.length === 0}
                    showIndicator={dropdownItems.length !== 0}
                    chipValueSelected={isCustomLinkExp(expiration.id)}
                  />
                  {customOpened && (
                    <ModalVideoExpiration
                      handleModalClose={closeCustomLinkExpirationModal}
                      handleSubmit={setLinkExpiration}
                      expiration={customDate}
                      setExpiration={setCustomDate}
                      buttonText='Set Link Expiration'
                    />
                  )}
                </>
              )}
              <LandingPageCard
                templateOptions={templateOptions}
                selectedTemplate={selectedTemplate}
                setSelectedTemplate={setSelectedTemplate}
                ctaOptions={ctaOptions}
                selectedCta={selectedCta}
                setSelectedCta={setSelectedCta}
                overlaysOptions={overlayOptions} /* SUS-1119 changes */
                selectedOverlay={selectedOverlay} /* SUS-1119 changes */
                setSelectedOverlay={setSelectedOverlay} /* SUS-1119 changes */
              />
              {!isQuotibleTemplateSelected && (
                <SendShareVideoForm
                  videoRequest={tempVideoRequest}
                  changeVideoRequest={setTempVideoRequest}
                  allowReactions={allowReactions}
                  reactionsEnabled={reactionsEnabled}
                  videoReplyEnabled={videoReplyEnabled}
                  setAllowReactions={setAllowReactions}
                  allowVideoReply={allowVideoReply}
                  setAllowVideoReply={setAllowVideoReply}
                  notify={notify}
                  setNotify={setNotify}
                />
              )}
            </Side>
          )}
          {elementBeingEditedId !== BUILDER_IDS.EMPTY && (
            <Side animate={true}>
              <EmailBuilderEditor
                key={elementBeingEditedId}
                emailContent={emailContent}
                setEmailContent={setEmailContent}
                video={video}
                elementBeingEditedId={elementBeingEditedId}
                changeElementBeingEdited={setElementBeingEditedId}
              />
            </Side>
          )}
        </ContentWrapper>
      </ModalBody>

      {showModalNoQuotePrompt && (
        <ModalNoQuotePrompt
          handleModalClose={() => setShowModalNoQuotePrompt(false)}
          handleSubmit={submitSend}
        />
      )}
      {showModalScheduleSendAndShare && (
        <ModalScheduleSendAndShare
          handleCloseSendAndShareModal={handleModalClose}
          handleModalClose={closeScheduleSendAndShareModal}
          modalTitle={scheduleModalTitle}
          sendData={sendData}
          attachments={attachments}
          emailContent={emailContent}
          overlayId={selectedOverlay?.value}
          templateId={selectedTemplate.value.toString()}
          linksetId={selectedCta?.value}
          useAutomotive={
            isAutomotiveService ||
            selectedTemplate.landingPageType === LandingPageType.AUTOMOTIVE
          }
          allowReactions={allowReactions}
          allowVideoReply={allowVideoReply}
          notifyWhenViewed={notify}
          skipSmsFlag={smsOnlyWithoutEmail}
          contactIds={generateSelectedContactIdsList()}
          groupIds={generateSelectedGroupIdsList()}
          leadIds={generateSelectedLeadIdsList()}
          dateTime={scheduleDateTime}
          setDateTime={setScheduleDateTime}
          timezone={scheduleTimezone}
          setTimezone={setScheduleTimezone}
          expiration={expiration}
          editSchedule={editSchedule}
        />
      )}
      {showConfirmDeleteScheduleModal && (
        <ModalDelete
          disabled={false}
          title={`Delete scheduled ${deleteScheduleModalTextExtra}`}
          orangeButtonText={'Keep it'}
          whiteButtonText={'Delete it'}
          text={`You are about to delete scheduled ${deleteScheduleModalTextExtra}.`}
          text1={''}
          hasConfirm={false}
          onClickOrangeButton={closeConfirmDeleteScheduleModal}
          onClickWhiteButton={handleDeleteSchedule}
          handleModalClose={closeConfirmDeleteScheduleModal}
        />
      )}
    </TabWrapper>
  );
};

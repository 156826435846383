import {
  ContactListItem,
  GroupListItem,
  VideoListItem,
  ContactsBulkUpdateParams,
  ContactOptOutListItem,
} from 'lib/api';
import { RepairOrderItem } from 'lib/api/repairOrders/types';
import { VideoStatus, VideoEmailStatus } from 'lib/const';
import { VerificationStatus } from 'lib/const/VerificationStatus';
import { PaginationConstants } from 'lib/const/PaginationConstants';
import React, { ReactElement } from 'react';
import { UsageReportPackageTypes } from 'lib/const/UsageReportPackageTypes';
import { ICustomer } from 'lib/api/users/getUser';
import { VideoData } from 'lib/hooks';
import { WTVListItem } from 'lib/api/wheelsTv/useWheelsTvVideosQuery';
import { DropItem } from 'lib/api/droplr/types';
import { VideoItem } from 'lib/api/videos/types';

export const WHITELABELINFO = 'whitelabel-info';

export type User = {
  id: string;
  customerId: string;
  resellerId: string;
  username: string;
  firstName?: string;
  lastName?: string;
};

export type folderContents = {
  videos: VideoListItem[];
  count?: number;
  page?: number;
  size?: number;
  search?: string;
};

export type wtvContents = {
  videos: WTVListItem[];
  count?: number;
  page?: number;
  size?: number;
  search?: string;
};

export type LibraryItem = {
  [key: string]: folderContents;
};

export type WTVItem = {
  [key: string]: wtvContents;
};

export type GlobalAction = {
  continueLoading?: boolean;
  type: string;
  payload?: any;
  error?: string;
  pathname?: string;
};

export type Reseller = {
  resellerId: number;
  resellerName: string;
  url: string;
  email: string;
  phoneNumber: string;
  customerId: number;
  playbackUrl: string;
  integration: number;
  fullReportAccess: number;
  easyCare: number;
  dealer: number;
  active: number;
};

export interface GlobalState {
  library: LibraryItem;
  dispatch: React.Dispatch<GlobalAction>;
}

export interface ApiState {
  firstAppLoad: number;
  loading: boolean;
  error?: string;
}

export type CDSVideoListFilter = {
  videoType?: string;
  advisorId?: string;
  userId?: string;
  status?: string;
  emailStatus?: string;
  folderId?: number;
  page: number;
  size: number;
  searchQuery: string;
};

export type VideoRequestListFilter = {
  videoType?: string;
  userId?: string;
  advisorId?: string;
  statuses?: string;
  videoId?: string;
  search?: string;
};

export type HandleDeleteVideoParams = {
  videoIds: (string | number)[];
  dispatch?: React.Dispatch<GlobalAction>;
  companyVideos?: boolean;
};

export interface ContactsState {
  contacts: {
    items: ContactListItem[];
    count: number;
    page: number;
    size: number;
    search?: string;
    loading?: boolean;
  };
  groups: {
    selected?: any;
    items: GroupListItem[];
    count: number;
    page: number;
    size: number;
    search?: string;
    loading?: boolean;
  };
  optOuts: {
    items: ContactOptOutListItem[];
    count: number;
    page: number;
    size: number;
    search?: string;
    loading?: boolean;
  };
}

export type QueryParams = {
  groupId?: string;
  videoId?: string;
  customerId?: string;
  search?: string;
  sort?: string;
  page?: number;
  size?: number;
  filterByDepartment?: boolean;
  startDate?: Date;
  endDate?: Date;
  order?: string;
  dispatch: React.Dispatch<GlobalAction>;
  conversation?: boolean;
};

export type VideoAttributesExtraParams = {
  downloadType?: string;
  videoAttributeId?: string;
  value?: string;
  videoType?: string;
  sync?: boolean;
  start?: number;
};

export type CreateParams = {
  data: any;
  dispatch: React.Dispatch<GlobalAction>;
  params?: VideoAttributesExtraParams;
};

export type UpdateContactParams = {
  data: ContactListItem | ContactsBulkUpdateParams;
  dispatch: React.Dispatch<GlobalAction>;
};

export type UpdateGroupParams = {
  data: GroupListItem;
  dispatch: React.Dispatch<GlobalAction>;
};

export type GetGroupParams = {
  groupId: string;
  dispatch: React.Dispatch<GlobalAction>;
};

export type AttributeItem = {
  attributeId?: string;
  title: string;
  slug: string;
  type: number;
  default?: string;
  isRequired?: number | boolean | undefined;
  isReadOnly?: number | boolean | undefined;
  isLocked?: number | boolean | undefined;
};

export type AttributesState = {
  attributes: { [id: string]: AttributeItem };
  videoAttributes: { [id: string]: any };
  order: string[];
  loading: boolean;
};

export type EmailBatchItem = {
  dealerId?: string;
  messageId: string;
  recipients: any[];
  batchId?: number;
  subject?: string;
  sentAt?: string;
  customer?: {
    business: string;
  };
  resend?: boolean;
};

export type EmailBatchesState = {
  emailBatches: { [id: string]: EmailBatchItem };
  count: number;
  page: number;
  size: number;
  sort: string;
  order: string[];
  loading: boolean;
};

export type UpdateAttributeParams = {
  data: AttributeItem;
  dispatch: React.Dispatch<GlobalAction>;
};

export type AnnotationItem = {
  annotationId?: string;
  type: string;
  startTimeText: string;
  endTimeText: string;
  startTime?: number;
  endTime?: number;
  text?: string;
  text2?: string;
  position?: string;
  imageUrl?: string;
  imageName?: string;
  isLinkEnabled?: boolean;
  useClassicEditor?: boolean;
};

export type AnnotationsState = {
  annotations: {
    [id: string]: {
      [id: string]: AnnotationItem;
    };
  };
  companyAnnotations: {
    [id: string]: {
      [id: string]: AnnotationItem;
    };
  };
  templates: { [id: string]: AnnotationItem };
  loading: boolean;
};

export type UpdateAnnotationParams = {
  data: AnnotationItem;
  videoId: string;
  customerId?: string;
  dispatch: React.Dispatch<GlobalAction>;
};

export type CustomReportItem = {
  recurrings?: any[];
  reportId?: string;
  title?: string;
  range?: string;
  reports?: string[];
  users?: string[];
  startDate?: string;
  endDate?: string;
  usersData?: Partial<User>[];
  includeNewUsers?: boolean;
  user?: User;
};

export type ReportDataProps = {
  type: string;
  reportId?: number;
  title?: string;
  reportData?: {
    deliveryTime: string;
    frequency: string;
    resellerReportId: number;
  };
};

export type ResellerReport = {
  customerId: number;
  customers: number[];
  deliveryTime: string;
  frequency: string;
  lastDate?: string;
  range: string;
  resellerId: number;
  resellerReportId: number;
  userId: number;
};

export type VideoDeleteItem = {
  deleteId?: string;
  customerId?: string;
  days?: number;
  createdAt?: string;
  notify?: boolean;
  exclude?: boolean;
  stopRequests?: any[];
};

export type VideoDeletesState = {
  videoDelete?: VideoDeleteItem;
  loading: boolean;
};

export type BoardItem = {
  name: string;
  id: string;
  privacy: string;
  password: string;
  uploadPrivacy: string;
  shortlink?: string;
  thumbnailUrl?: string;
  accessList?: Array<any>;
  userStatus?: string;
  creator?: { [key: string]: string };
  groupsAccessList?: Array<any>;
};

export type DroplrState = {
  drops: {
    items: {
      [id: string]: DropItem;
    };
    order: string[];
    count: number;
    loading: boolean;
  };
  boards: {
    items: {
      [id: string]: BoardItem;
    };
    order: string[];
    count: number;
    loading: boolean;
  };
};

type ScriptUser = {
  firstName: string;
  lastName: string;
};
export type ScriptItem = {
  scriptId: string;
  title: string;
  content: string;
  sharedWithCompany: boolean;
  customerId: number;
  userId: number;
  user: ScriptUser;
};

export type TeleprompterState = {
  scripts: {
    [id: string]: ScriptItem;
  };
  count: number;
  loading: boolean;
};

export type VideoTag = {
  tagId: number;
  tag: string;
};

export type VideoActivity = {
  videoId?: number | string;
  activityText?: string;
  createdAt?: Date;
};

export type VideoListAutomotiveItem = {
  pinnedVideo: { [key: string]: string };
  id: string;
  title: string;
  recordDate: string;
  views: string;
  thumbnail: string;
  videoRequest: VideoRequest;
  repairOrder?: RepairOrderItem;
  videoLength?: number;
  videoSource: string;
  playerBackgroundColor: string;
  playButtonPosition: string;
  playerIconsAndTextColor: string;
  tags: VideoTag[];
  user: any;
  folder?: string;
  protected: number;
  processing?: number;
  access?: string;
};

export type VideoRequest = {
  videoRequestId: string;
  repairOrderNumber: string;
  videoType: string;
  title: string;
  customerName: string;
  note: string;
  videoId: string;
  video: VideoListAutomotiveItem;
  repairOrder?: RepairOrderItem;
  user: any;
  advisor: any;
  status: VideoStatus;
  emailStatus: VideoEmailStatus;
  createdAt: string;
  vin?: string;
  automotiveDepartment: number;
  sharedWithCompany?: boolean;
  userId?: string;
  advisorId?: string;
};

export type LeadQueryParams = {
  size?: number;
  page?: number;
  sort?: string;
  search?: string;
  filterByStatus?: string;
  dispatch: React.Dispatch<GlobalAction>;
};

export type LeadItem = {
  leadId: string;
  customerId?: number;
  integrationDealerId?: number;
  integrationContactId?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  source?: string;
  addedOn?: string;
  createdAt?: string;
};

export type LeadState = {
  leads: {
    [id: string]: LeadItem;
  };
  count: number;
  loading: boolean;
  order: string[];
};

export type GetScriptParams = {
  scriptId: string;
  dispatch: React.Dispatch<GlobalAction>;
};

export type UpdateScriptParams = {
  data: object;
  scriptId: string;
  dispatch: React.Dispatch<GlobalAction>;
};
export type UserState = {
  users: {
    items: any[];
    order: string[];
    count: number;
    loading: boolean;
    totalAllowedUsers: number;
    totalActiveUsers: number;
  };
  departments: {
    items: any[];
    count: number;
    loading: boolean;
  };
};

export type SnippetQuery = {
  size?: number;
  page?: number;
  sort?: string;
  search?: string;
  sharedWith?: string;
  dispatch: React.Dispatch<GlobalAction>;
};

export type SMSUserState = {
  users: {
    items: any[];
    count: number;
    loading: boolean;
  };
  messages: {
    items: any[];
    count: number;
    loading: boolean;
  };
  overview: {
    sent: number | string;
    received: number | string;
    mostActiveUsers: any[];
    chatCount: number | string;
    unansweredChats: any[];
    loading: boolean;
    graph: any[];
  };
};

export type BillingState = {
  subscription: {
    card: any;
    plan: any;
    upcomingInvoice: any;
    loading: boolean;
    addons: any;
    cancelAt: any;
    currentPeriodEnd: any;
    nextPeriodStart: any;
    totalActiveUsers: number | string;
    totalAllowedUsers: number | string;
  };
  invoices: {
    items: any[];
    hasMore: boolean;
    loading: boolean;
  };
  cards: {
    items: any[];
    hasMore: boolean;
    loading: boolean;
  };
};

export type InventoryItemMedia = {
  inventoryItemMediaId: string;
  itemId: string;
  mediaType: number;
  mediaSource: string;
};

export type InventoryItem = {
  itemId: string;
  customerId: string;
  sold: boolean;
  detailsPageUrl: string;
  price: string;
  title: string;
  vin: string;
  stockNumber: string;
  itemCondition: string;
  engine: string;
  make: string;
  model: string;
  trim: string;
  year: string;
  mileage: string;
  exteriorColor: string;
  interiorColor: string;
  transmission: string;
  manufacturer?: string;
  vehicleType?: string;
  createdAt: Date;
  media?: InventoryItemMedia[];
  videosRecorded?: number | string;
  videoRequests?: VideoRequest[];
  options?: string[];
};

export type SaleOfferItem = {
  saleOfferId: string;
  videoId: string;
  status: string;
  firstName: string;
  lastName: string;
  phone?: string;
  email?: string;
  emailSubject?: string;
  emailText?: string;
  smsText?: string;
  sendEmail: boolean;
  sendSms: boolean;
  sendVehicle: boolean;
  videoRequest?: VideoRequest;
  user?: any;
  advisor?: any;
  createdAt?: string;
  vin?: string;
  video?: VideoItem;
};

export type VideoReactionItem = {
  reactionId: string;
  videoId: string;
  type: number;
  playbackPosition: number;
  name: string;
  email?: string;
  phone?: string;
  emoticonId?: number;
  text?: string;
  createdAt?: string;
};

export type VideoReactionState = {
  videoReactions: {
    [id: string]: VideoReactionItem;
  };
  loading: boolean;
};

export type ReactionWithIcon = VideoReactionItem & {
  icon: ReactElement | null;
  smallIcon: ReactElement | null;
};

export type ReactionsPosition = {
  position: number;
  reactions: ReactionWithIcon[];
};

export type Location = {
  locationId: number;
  name: string;
  streetAddress?: string;
  customerId: number;
  state?: string;
};

export type AttachedFile = {
  name: string;
  size: number;
  type: string;
  url: string;
  id?: string;
};

export type FileUploadStatus = {
  status: string;
  progress: number;
  currentUpload: number;
  sizeText?: string;
};

export type SuperAdminCustomer = {
  customerId: number;
  resellerId: number;
  business: string;
  firstName: string;
  lastName: string;
  email: string;
  verified: number;
  reseller?: Reseller;
  resellerName?: string;
  userCount: string;
  maxUsers: string;
};

export type SuperAdminOrganization = {
  organizationId: number;
  name: string;
  customerOrganizations?: CustomerOrganization[];
  userOrganizationAccesses?: UserOrganizationAccess[];
  organizationRegions?: OrganizationRegion[];
};

export type CustomerListFilter = {
  status:
    | VerificationStatus.ALL
    | VerificationStatus.INACTIVE
    | VerificationStatus.ACTIVE;
  resellerId: number;
  page: number;
  size: number;
  searchQuery?: string;
  sortKey?: string;
  order?: PaginationConstants.ASCENDING | PaginationConstants.DESCENDING;
};

export type OptOutListFilter = {
  page: number;
  size: number;
  sortKey?: string;
  order?: PaginationConstants.ASCENDING | PaginationConstants.DESCENDING;
  searchQuery?: string;
  customerId: number | undefined;
};

export type CustomerStatistics = {
  active?: number;
  inActive?: number;
};

export type CustomerOrganization = {
  id: number | string;
  customerId: number;
  organizationId: number;
  organizationRegionId?: number;
  organizationRegion: OrganizationRegion;
  business?: string;
  customer?: ICustomer;
};

export type SuperAdminCustomerDetails = {
  customerId?: number;
  resellerId?: number;
  business?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  emailsSentLimit?: number;
  verified: number;
  reseller?: Reseller;
  newMaxUsers?: number;
  maxUsers?: number;
  userCount?: number;
  phone1?: string;
  phone2?: string;
  url?: string;
  referredBy?: string;
  referralAgent?: number;
  accountManager?: number;
  crmProviderId?: number;
  customerLaunchDate?: number;
  activeEndDate?: Date;
  wheelsTV?: number;
  freeTrial?: number;
  smsEnabled?: number;
  freeSmsPerMonth?: number;
  paidSmsPerMonth?: number;
  versionTwoEnabled?: number;
  versionThreeEnabled?: number;
  versionCDSEnabled?: number;
  transcriptions?: number;
  transcriptionLicenses?: number;
  location?: string;
  number?: string;
  password?: string;
  username?: string;
  covideoPackageId?: number;
  packageId?: number;
  remainingSmsCount?: number;
  sentSmsCount?: number;
  customerOrganizations?: CustomerOrganization[];
  hasMultiLocation?: number;
  parentCustomerId?: number;
  salesforceAccountId?: string;
  credit700Url?: string;
  automotiveAccess?: boolean;
  liveAccess?: number;
  hasCovideoMagic?: number;
  vdpHomeNetEnabled?: number;
  vdpIPacketEnabled?: number;
  lesaAccess: number;
  autominerEnabled?: boolean;
  weeklyReportEnabled?: boolean;
  autominerGroupId?: string;
  audiDealerCode?: string;
  audiAreaCode?: string;
  audiSubAreaCode?: string;
  timezone?: string;
  jdPowerVDPEnabled?: number;
};

export type SuperAdminUser = {
  id: number;
  firstName: string;
  lastName: string;
  admin: number;
  verified: number;
  customerId: number;
  loginDate?: number;
  email?: string;
  phone1?: string;
  customerName?: string;
  username?: string;
  access?: number;
  mlAutoCreated: boolean;
  automotiveRole: number;
  automotiveAccess?: boolean;
  inviteSent?: boolean;
  transcriptionAccess?: boolean;
  droplrAccess?: boolean;
  signupDate?: Date;
  departmentName?: string;
};

export type SuperAdminReseller = {
  resellerId: number;
  resellerName: string;
  phoneNumber: string;
  url: string;
  resellerReportingEnabled: boolean;
  playbackUrl: string;
  firstName?: string;
  lastName?: string;
  userName?: string;
  password?: string;
  email?: string;
  customerId?: number;
  integration?: number;
  fullReportAccess?: number;
  easyCare?: number;
  dealer?: number;
  poweredByLogo?: StaticRangeInit;
  active?: number;
};

export type UserListFilter = {
  status:
    | VerificationStatus.ALL
    | VerificationStatus.INACTIVE
    | VerificationStatus.ACTIVE;
  customerId?: number;
  page: number;
  size: number;
  searchQuery?: string;
  sortKey?: string;
  order?: PaginationConstants.ASCENDING | PaginationConstants.DESCENDING;
};

export type TableField = {
  value: string;
  label: string;
  sortable?: boolean;
  sortKey?: string;
  width?: number;
  height?: string;
};

export type AgentResponse = {
  agentId: string;
  firstName: string;
  lastName: string;
  business: string;
};

export type AccountManagerResponse = {
  agentId: string;
  userId: string;
  firstName: string;
  lastName: string;
};

export type CrmProviders = {
  id: number;
  name: string;
  logo: string;
  mergeCode: string;
};

export type UsageReport = {
  id: number;
  username: string;
  firstName: string;
  lastName?: string;
  email: string;
  videos?: number;
  loginDate?: number;
  lastVideoDate?: number;
  lastSentDate?: number;
  plan: UsageReportPackageTypes;
};

export type UsageReportsListFilter = {
  status:
    | VerificationStatus.ALL
    | VerificationStatus.INACTIVE
    | VerificationStatus.ACTIVE;
  customerId?: number;
  packageId?: number;
  page: number;
  size: number;
  searchQuery?: string;
  sortKey?: string;
  order?: PaginationConstants.ASCENDING | PaginationConstants.DESCENDING;
};

export type UsageReportsStatistics = {
  recordedVideos?: number;
  sentEmails?: number;
  optOuts?: number;
  activeUsers?: number;
};

export type UserDetailsData = {
  username: string;
  password: string;
  manualPassword: boolean;
  customerId: number;
  firstName: string;
  lastName: string;
  transcriptionAccess?: boolean;
  trialAccount: boolean;
  access: number | string;
  verified: boolean;
  email: string;
  email2?: string;
  phone1: string;
  phone2?: string;
  cellPhone?: string;
  qraAccess: boolean;
  title: string;
  departmentId: number;
  departmentName: string;
  isResetPassword: boolean;
  signupDate: string;
  mobileSMSAccess: boolean;
  id?: number;
  resellerAdmin?: number;
  resellerId?: number;
  automotiveRole?: number;
  guideCategoriesAdministration?: boolean;
  template?: number;
  exitlink?: number;
  requiresPasswordChange: boolean;
};

export type Region = {
  name: string;
  regionId: number;
  resellerId: number;
};

export type OrganizationRegion = {
  name: string;
  organizationRegionId: number;
  organizationId: number;
};

export type IOrganization = {
  organizationId: number;
  name: string;
};

export type UserOrganizationAccess = {
  id: number;
  organizationId: string | number;
  limitToRegions: number[];
  enableSignInAs: boolean;
  organization?: IOrganization;
  user: User;
};

export interface Option {
  value: string;
  label: string;
}

export type Activity = {
  receiverId: number;
  recipient: {
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
  };
  method: 'sms' | 'email' | 'quickshare';
  video: VideoData;
  emailStatus: string;
  viewCount: number;
  lastViewDate: string;
  dateSent: string;
  user?: {
    id: number;
    firstName: string;
    lastName: string;
  };
  videoTitle: string;
  videoId: number;
  dateSentUnix: number;
};

export type ActivityResponse = {
  count: number;
  activities: Activity[];
};

export type ActivityHeader = {
  dateSent: string;
  recipientName: string;
  method: string;
  videoTitle: string;
  emailStatus: string;
  viewCount: string;
  lastViewDate: string;
  email?: string;
  phone?: string;
  user?: string;
};

export type UpdateUsersFastPasswordRequest = {
  newPassword: string;
  userIds: number[];
};

import React from 'react';
import styled from 'styled-components/macro';
import {
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
  Table,
  CheckboxInput,
} from 'lib/components';
import dayjs from 'dayjs';
import { successToast } from 'lib/components/toasts/success';
import { STANDARD_DATE_FORMAT } from 'lib/const/DateFormat';
import { ILead } from 'lib/api/leads/getLeads';

const ClickableElement = styled.div`
  cursor: pointer;
`;
type TableCellProps = {
  justifyContent?: string;
  padding?: string;
};
const TableCell = styled.div<TableCellProps>`
  width: auto;
  justify-content: ${props => props.justifyContent || 'flex-start'};
  padding: ${props => props.padding || '0'};
  display: flex;
  position: relative;
`;

const CheckWrapper = styled.div`
  margin-right: 16px;
`;

const columnWidths = [50, 180, 180, 180, 180];

const tableFields = [
  {
    value: 'name',
    label: 'Name',
    padding: '0 16px 0 0',
    align: 'flex-start',
  },
  {
    value: 'email',
    label: 'Email',
    padding: '0 16px 0 0',
    align: 'flex-start',
  },
  {
    value: 'phone',
    label: 'Phone',
    padding: '0 16px 0 0',
    align: 'flex-start',
  },
  {
    value: 'sends',
    label: 'Times contacted',
    padding: '0 16px 0 0',
    align: 'flex-end',
  },
  {
    value: 'addedOn',
    label: 'Added on',
    padding: '0 16px 0 0',
    align: 'flex-end',
  },
];

type Props = {
  count: number;
  page: number;
  size: number;
  leadsArray: ILead[];
  selectedLead: number[];
  onPaginationChange: ({ page, size }: { page: number; size: number }) => void;
  setSelectedLead: (selectedLead: number[]) => void;
};

export const LeadTable = ({
  leadsArray,
  selectedLead,
  count,
  onPaginationChange,
  page,
  size,
  setSelectedLead,
}: Props) => {
  const cb = navigator.clipboard;
  const phoneCellClick = (value: string) => {
    cb.writeText(value).then(() => {
      successToast({ title: 'Customer phone copied to clipboard' });
    });
  };
  const emailCellClick = (value: string) => {
    cb.writeText(value).then(() => {
      successToast({ title: 'Customer email copied to clipboard' });
    });
  };

  const getCellContent = (
    item: {
      value: string;
      label: string;
      padding: string;
      align: string;
    },
    lead: ILead,
    cellValue: string
  ) => {
    if (item.value === 'name') {
      return lead['firstName'] || lead['lastName']
        ? `${lead['firstName']} ${lead['lastName']}`
        : '-';
    }

    if (item.value === 'addedOn') {
      return dayjs(cellValue).format(`${STANDARD_DATE_FORMAT} HH:mm A`);
    }

    if (item.value === 'email') {
      return !cellValue ? (
        '-'
      ) : (
        <ClickableElement onClick={() => emailCellClick(cellValue)}>
          {cellValue}
        </ClickableElement>
      );
    }

    if (item.value === 'phone') {
      return !cellValue ? (
        '-'
      ) : (
        <ClickableElement onClick={() => phoneCellClick(cellValue)}>
          {cellValue}
        </ClickableElement>
      );
    }

    return cellValue;
  };

  return (
    <TableContextProvider
      total={count}
      onChange={onPaginationChange}
      initPage={page}
      initSize={size}
    >
      <Table
        columnWidths={columnWidths}
        compact={true}
        relative={true}
        headers={[
          '',
          ...tableFields.map(item => {
            return (
              <TableCell justifyContent={item.align} padding={item.padding}>
                {item.label}
              </TableCell>
            );
          }),
        ]}
        hoverable={false}
        fixColumnIndex='left'
        rows={leadsArray.map((lead, index: number) => ({
          key: index,
          columns: [
            <TableCell>
              <CheckWrapper>
                <CheckboxInput
                  width='24px'
                  blueCheck={true}
                  checked={selectedLead.includes(lead.leadId)}
                  onChange={(e: React.SyntheticEvent) => {
                    const { checked } = e.target as HTMLInputElement;
                    if (checked)
                      setSelectedLead([...selectedLead, lead.leadId]);
                    else
                      setSelectedLead(
                        selectedLead.filter(e => e != lead.leadId)
                      );
                  }}
                />
              </CheckWrapper>
            </TableCell>,
            ...tableFields.map(item => {
              const cellValue = lead[item.value as keyof ILead] as string;

              return (
                <TableCell justifyContent={item.align} padding={item.padding}>
                  {getCellContent(item, lead, cellValue)}
                </TableCell>
              );
            }),
          ],
        }))}
      />
      <TableFooter>
        <TablePaginationNew />
        <TablePaginationSizeNew text='Show leads:' />
      </TableFooter>
    </TableContextProvider>
  );
};

import * as React from 'react';
import styled from 'styled-components/macro';
import { LoadingIndicator } from 'lib/components';
import { useVideoViewersMessagesStore } from 'lib/api/useVideoViewersMessagesStore';
import { theme } from 'lib/style';
import { RecipientType } from '../../../../../lib/hooks';
import { CSSProperties } from 'react';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { addEmailReceiver, sendSms } from 'lib/api';
import { useSendVideoEmail } from 'lib/api/email/useSendVideoEmail';
import { SendEmailParams } from 'lib/api/email/types';
import { Button } from 'react-covideo-common';
import { IoMdClose } from 'react-icons/io';
import { replaceSpecialCharacters } from 'lib/utils/functions';

const ModalContainer = styled.div`
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 5;
  text-align: left;
  padding-top: 40px;
`;

const Flex = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalItem = styled.div`
  display: block;
  position: relative;
  width: 800px;
  background-color: #fff;
  border-top: solid 7px #3498db;
  border-radius: 4px;
  margin: 0 16px;
`;

const Content = styled.div`
  padding: 30px 30px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 300px;
`;

const ContentHeader = styled.div`
  ${theme.fontBold700}
  font-size: ${theme.fontSizes.xl};
  color: ${theme.palette.themeDark};
  margin-bottom: 10px;
`;

const ContentBody = styled.div`
  min-height: 200px;
  max-height: 350px;
  overflow: auto;
  ${theme.fontNormal400}
  font-size: ${theme.fontSizes.m};
  color: ${theme.palette.blackRgb75};
  margin: 0;
  width: 100%;
  margin-bottom: 25px;
  opacity: 1;
  transition: all 0.3s;
`;

const ContentFooter = styled.div`
  padding-bottom: 12px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 8px;
`;

const ContentSubject = styled.div`
  padding-bottom: 10px;
  width: 100%;
`;

const CloseButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
`;

//todo
type Props = {
  videoId: string;
  isVisible: boolean;
  disabled?: boolean;
  handleModalClose: () => void;
  receiverID: number;
  isSms: boolean;
  allowVideoResend?: boolean;
};

export const ModalEmailPreview = (props: Props) => {
  const {
    videoId,
    isVisible = false,
    disabled = false,
    handleModalClose,
    receiverID,
    isSms,
    allowVideoResend = true,
  } = props;

  const {
    error: isError,
    loading: isLoading,
    response,
  } = useVideoViewersMessagesStore({
    videoId,
    recipientId: receiverID,
  });

  const { mutateAsync: sendEmail } = useSendVideoEmail(videoId);

  const htmlDecode = (content: string): string => {
    let e = document.createElement('div');
    content = atob(content);
    if (content) {
      e.innerHTML = content
        .replace(/amp;/gi, '')
        .replace(/\n/gi, '')
        .replace(/\r/gi, '')
        .replace(/\s{2,}/gi, '');
    }

    return e.childNodes.length && e.childNodes[0].nodeValue
      ? e.childNodes[0].nodeValue
      : e.innerHTML;
  };

  let htmlContent = '';

  if (response) {
    const originalMessage = htmlDecode(response.message?.originalMessage || '');
    htmlContent =
      originalMessage !== '<br>'
        ? originalMessage
        : htmlDecode(response?.message?.body || '');
    htmlContent = replaceSpecialCharacters(htmlContent);
  }
  const resend = async () => {
    if (isSms) {
      try {
        await sendSms(response.recipient.emailAddress, htmlContent);
        await addEmailReceiver(
          videoId,
          response.recipient.emailAddress,
          htmlContent
        );
        successToast({
          title: 'SMS resent successfully!',
        });
        handleModalClose();
      } catch (error) {
        errorToast({
          title:
            'An error occurred preparing your sms, please try again later!',
        });
      }

      return;
    }
    const email: SendEmailParams = {
      templateId: response.message.designId,
      linksetId: response.message.templateLinksGroupId,
      overlayId:
        response.message.designId === -1
          ? response.message.templateLinksGroupId
          : '',
      recipients: [
        {
          text: response.recipient.emailAddress,
          id: response.recipient.emailAddress,
          type: RecipientType.USER,
        },
      ],
      subject: response.message.subject,
      html: htmlContent,
      attachments: [response.message.attachments].filter(Boolean),
      vin: response.message.vin,
      notifyUser: !!parseInt(response.message.notify),
      allowReactions: false,
      allowVideoReply: false,
    };

    const result = await sendEmail(email).catch(err => err);

    if (result instanceof Error) {
      errorToast({
        title:
          'An error occurred preparing your email, please try again later!',
      });
    } else {
      successToast({
        title: 'Email resent successfully!',
      });
      handleModalClose();
    }
  };

  const no_message_found_style: CSSProperties = {
    margin: '90px',
    fontSize: '18px',
    width: '500px',
  };

  if (!isVisible) {
    return null;
  }

  return (
    <ModalContainer>
      <Flex>
        <ModalItem>
          <CloseButtonContainer>
            <Button
              disabled={disabled}
              onClick={handleModalClose}
              icon={<IoMdClose size={24} color={theme.palette.black} />}
              variant='transparent'
            />
          </CloseButtonContainer>
          <Content>
            <LoadingIndicator isLoading={isLoading} error={isError} />
            {!isLoading && !isError && (
              <>
                <ContentHeader>
                  {`${isSms ? 'SMS' : 'Email'} sent to ${
                    response.recipient.emailAddress
                  }`}
                </ContentHeader>
                {!isSms && (
                  <ContentSubject>
                    {'Subject : ' + response.message.subject}
                  </ContentSubject>
                )}
                <ContentBody>
                  {(response.recipient.sentItemId > 0 && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: htmlContent,
                      }}
                    />
                  )) || (
                    <div>
                      <div>
                        <div style={no_message_found_style}>
                          No email message found for this viewer. If this
                          message was sent from the Covideo for Outlook Addin,
                          please go to your Sent Items in Outlook to view the
                          message
                        </div>
                      </div>
                    </div>
                  )}
                </ContentBody>
                {response.recipient.sentItemId > 0 && allowVideoResend && (
                  <ContentFooter>
                    <Button
                      text='Cancel'
                      onClick={handleModalClose}
                      variant='secondary'
                    />
                    <Button text='Resend' onClick={() => resend()} />
                  </ContentFooter>
                )}
              </>
            )}
          </Content>
        </ModalItem>
      </Flex>
    </ModalContainer>
  );
};
